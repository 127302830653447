import { Icon, IconClose, IconMagnifier } from "@flixbus/honeycomb-icons-react";
import { Button, Checkbox, Input } from "@flixbus/honeycomb-react";
import { normalizeText } from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import translatePartnerTags from "../../../shared/helpers/translatePartnerTags/translatePartnerTags";
import { PartnerTag } from "../../../shared/types/schema";
import * as checkBoxCss from "../form_field_styles.scss";
import * as css from "./PartnerTagsFilter.scss";

const PartnerTagsFilter: React.FC = () => {
  const [searchValue, setSearchValue] = React.useState<string>("");
  const data = Object.values(PartnerTag);
  const [, meta, helper] = useField<Array<PartnerTag>>("partnerTags");

  const { formatMessage } = useIntl();

  const filterCheckList = (partnerTags: Array<PartnerTag>) => {
    const filteredTags = partnerTags.filter((partnerTag) => {
      if (!searchValue) return true;
      return normalizeText(translatePartnerTags(partnerTag)).includes(
        normalizeText(searchValue)
      );
    });

    if (filteredTags.length) {
      return filteredTags.map((partnerTag) => (
        <Checkbox
          extraClasses={checkBoxCss.checkbox}
          key={partnerTag}
          label={translatePartnerTags(partnerTag)}
          id={partnerTag}
          value="tiny"
          small
          defaultChecked={meta.value.includes(partnerTag)}
          onChange={(e) => {
            if (e.target.checked) {
              helper.setValue([...meta.value, partnerTag]);
            } else {
              helper.setValue(meta.value.filter((tag) => tag !== partnerTag));
            }
          }}
        />
      ));
    }

    return (
      <div
        className={css.textMessage}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: formatMessage({ id: "search.noResults.extended" }, { ignoreTag: true }),
        }}
      />
    );
  };

  return (
    <div>
      <Input
        extraClasses={css.input}
        id="partner-tags-search"
        aria-label={formatMessage({ id: "partnerTags.search.placeholder" })}
        type="text"
        placeholder={formatMessage({ id: "partnerTags.search.placeholder" })}
        iconLeft={<Icon InlineIcon={IconMagnifier} />}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        iconRight={
          searchValue.length ? (
            <Button
              id="button-reset-search"
              appearance="link"
              onClick={() => setSearchValue("")}
            >
              <Icon InlineIcon={IconClose} />
            </Button>
          ) : undefined
        }
      />
      <div className={css.checklist}>{filterCheckList(data)}</div>
    </div>
  );
};

export default PartnerTagsFilter;
