import { useField } from "formik";
import * as React from "react";
import { InventoryRestriction } from "../../../../shared/types/schema";
import InterconnectionRuleSelect from "../interconnectionRuleSelect/InterconnectionRuleSelect";
import InventoryRestrictionSelect from "../inventoryRestrictionSelect/InventoryRestrictionSelect";

type Props = {
  hasMobilityPartner?: boolean;
};

const InterconnectionRulesInput: React.FC<Props> = ({ hasMobilityPartner }) => {
  const [, meta] = useField<InventoryRestriction>("inventoryRule.inventoryRestriction");

  return (
    <>
      <InventoryRestrictionSelect hasMobilityPartner={hasMobilityPartner} />
      {meta.value !== InventoryRestriction.StandaloneOnly && (
        <InterconnectionRuleSelect hasMobilityPartner={hasMobilityPartner} />
      )}
    </>
  );
};

export default InterconnectionRulesInput;
