import { SelectGroup } from "@flixbus/honeycomb-react";
import { ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CooperationType } from "../../../../shared/types/schema";
import * as css from "./CooperationTypeSelect.scss";

type Props = {
  readOnly?: boolean;
};

// FIXME: Replace with FormikSelectGroup the next time you touch this file!
const CooperationTypeSelect: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] = useField<CooperationType>("cooperationType");

  if (readOnly) {
    return (
      <ReadOnlyFormField
        label={formatMessage({ id: "lineForm.cooperationType.select.label.readOnly" })}
      >
        <FormattedMessage id="line.model" values={{ cooperationType: meta.value }} />
      </ReadOnlyFormField>
    );
  }

  return (
    <SelectGroup
      label={`${formatMessage({
        id: "lineForm.cooperationType.select.label.editable",
      })} *`}
      options={Object.values(CooperationType).map((cooperationType) => {
        return {
          id: cooperationType,
          value: cooperationType,
          name: "contract-model",
          label: formatMessage(
            { id: "line.model" },
            {
              cooperationType,
            }
          ),
          onBlur: () => helper.setTouched(true),
          onClick: () => {
            helper.setValue(cooperationType, true);
            // https://github.com/formium/formik/issues/2083#issuecomment-571259235
            helper.setTouched(true, false);
          },
          defaultChecked: cooperationType === meta.value,
        };
      })}
      id="cooperationType"
      error={!!(meta.error && meta.touched)}
      infoError={meta.error}
      extraClasses={css.container}
    />
  );
};

export default CooperationTypeSelect;
