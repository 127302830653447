// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type FindScheduleStatusHistoryQueryVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type FindScheduleStatusHistoryQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    statusHistory: Array<{
      __typename?: "ScheduleStatusHistoryEntry";
      status: Types.ScheduleStatus;
      description?: string | null;
      created: string;
      createdBy: string;
    }>;
  };
};

export type CopyScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type CopyScheduleMutation = {
  __typename?: "Mutation";
  copySchedule: { __typename?: "Schedule"; id: string };
};

export type DeleteScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type DeleteScheduleMutation = { __typename?: "Mutation"; deleteSchedule: boolean };

export type CreateConceptChangeMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type CreateConceptChangeMutation = {
  __typename?: "Mutation";
  createConceptChange: { __typename?: "Schedule"; id: string };
};

export type ChangeScheduleStatusMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
  newStatus: Types.ScheduleStatus;
  comment: Types.Scalars["String"]["input"];
}>;

export type ChangeScheduleStatusMutation = {
  __typename?: "Mutation";
  changeStatus: {
    __typename?: "Schedule";
    id: string;
    status: Types.ScheduleStatus;
    displayedScheduleStatus: Types.DisplayedScheduleStatus;
  };
};

export type ActivateOnSaleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type ActivateOnSaleMutation = { __typename?: "Mutation"; activateOnSale: boolean };

export type ArchiveScheduleMutationVariables = Types.Exact<{
  scheduleId: Types.Scalars["ID"]["input"];
}>;

export type ArchiveScheduleMutation = {
  __typename?: "Mutation";
  archiveSchedule: boolean;
};

export const FindScheduleStatusHistoryDocument = gql`
  query findScheduleStatusHistory($scheduleId: ID!) {
    findSchedule(id: $scheduleId) {
      id
      statusHistory {
        status
        description
        created
        createdBy
      }
    }
  }
`;

/**
 * __useFindScheduleStatusHistoryQuery__
 *
 * To run a query within a React component, call `useFindScheduleStatusHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindScheduleStatusHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindScheduleStatusHistoryQuery({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useFindScheduleStatusHistoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  > &
    (
      | { variables: FindScheduleStatusHistoryQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >(FindScheduleStatusHistoryDocument, options);
}
export function useFindScheduleStatusHistoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >(FindScheduleStatusHistoryDocument, options);
}
export function useFindScheduleStatusHistorySuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindScheduleStatusHistoryQuery,
        FindScheduleStatusHistoryQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindScheduleStatusHistoryQuery,
    FindScheduleStatusHistoryQueryVariables
  >(FindScheduleStatusHistoryDocument, options);
}
export type FindScheduleStatusHistoryQueryHookResult = ReturnType<
  typeof useFindScheduleStatusHistoryQuery
>;
export type FindScheduleStatusHistoryLazyQueryHookResult = ReturnType<
  typeof useFindScheduleStatusHistoryLazyQuery
>;
export type FindScheduleStatusHistorySuspenseQueryHookResult = ReturnType<
  typeof useFindScheduleStatusHistorySuspenseQuery
>;
export const CopyScheduleDocument = gql`
  mutation CopySchedule($id: ID!) {
    copySchedule(id: $id) {
      id
    }
  }
`;

/**
 * __useCopyScheduleMutation__
 *
 * To run a mutation, you first call `useCopyScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyScheduleMutation, { data, loading, error }] = useCopyScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCopyScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CopyScheduleMutation,
    CopyScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CopyScheduleMutation,
    CopyScheduleMutationVariables
  >(CopyScheduleDocument, options);
}
export type CopyScheduleMutationHookResult = ReturnType<typeof useCopyScheduleMutation>;
export const DeleteScheduleDocument = gql`
  mutation DeleteSchedule($id: ID!) {
    deleteSchedule(id: $id)
  }
`;

/**
 * __useDeleteScheduleMutation__
 *
 * To run a mutation, you first call `useDeleteScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduleMutation, { data, loading, error }] = useDeleteScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    DeleteScheduleMutation,
    DeleteScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    DeleteScheduleMutation,
    DeleteScheduleMutationVariables
  >(DeleteScheduleDocument, options);
}
export type DeleteScheduleMutationHookResult = ReturnType<
  typeof useDeleteScheduleMutation
>;
export const CreateConceptChangeDocument = gql`
  mutation CreateConceptChange($id: ID!) {
    createConceptChange(id: $id) {
      id
    }
  }
`;

/**
 * __useCreateConceptChangeMutation__
 *
 * To run a mutation, you first call `useCreateConceptChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConceptChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConceptChangeMutation, { data, loading, error }] = useCreateConceptChangeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateConceptChangeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateConceptChangeMutation,
    CreateConceptChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateConceptChangeMutation,
    CreateConceptChangeMutationVariables
  >(CreateConceptChangeDocument, options);
}
export type CreateConceptChangeMutationHookResult = ReturnType<
  typeof useCreateConceptChangeMutation
>;
export const ChangeScheduleStatusDocument = gql`
  mutation changeScheduleStatus(
    $scheduleId: ID!
    $newStatus: ScheduleStatus!
    $comment: String!
  ) {
    changeStatus(scheduleId: $scheduleId, newStatus: $newStatus, comment: $comment) {
      id
      status
      displayedScheduleStatus
    }
  }
`;

/**
 * __useChangeScheduleStatusMutation__
 *
 * To run a mutation, you first call `useChangeScheduleStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeScheduleStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeScheduleStatusMutation, { data, loading, error }] = useChangeScheduleStatusMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *      newStatus: // value for 'newStatus'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useChangeScheduleStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ChangeScheduleStatusMutation,
    ChangeScheduleStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ChangeScheduleStatusMutation,
    ChangeScheduleStatusMutationVariables
  >(ChangeScheduleStatusDocument, options);
}
export type ChangeScheduleStatusMutationHookResult = ReturnType<
  typeof useChangeScheduleStatusMutation
>;
export const ActivateOnSaleDocument = gql`
  mutation ActivateOnSale($scheduleId: ID!) {
    activateOnSale(scheduleId: $scheduleId)
  }
`;

/**
 * __useActivateOnSaleMutation__
 *
 * To run a mutation, you first call `useActivateOnSaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOnSaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOnSaleMutation, { data, loading, error }] = useActivateOnSaleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useActivateOnSaleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ActivateOnSaleMutation,
    ActivateOnSaleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ActivateOnSaleMutation,
    ActivateOnSaleMutationVariables
  >(ActivateOnSaleDocument, options);
}
export type ActivateOnSaleMutationHookResult = ReturnType<
  typeof useActivateOnSaleMutation
>;
export const ArchiveScheduleDocument = gql`
  mutation ArchiveSchedule($scheduleId: ID!) {
    archiveSchedule(scheduleId: $scheduleId)
  }
`;

/**
 * __useArchiveScheduleMutation__
 *
 * To run a mutation, you first call `useArchiveScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveScheduleMutation, { data, loading, error }] = useArchiveScheduleMutation({
 *   variables: {
 *      scheduleId: // value for 'scheduleId'
 *   },
 * });
 */
export function useArchiveScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ArchiveScheduleMutation,
    ArchiveScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ArchiveScheduleMutation,
    ArchiveScheduleMutationVariables
  >(ArchiveScheduleDocument, options);
}
export type ArchiveScheduleMutationHookResult = ReturnType<
  typeof useArchiveScheduleMutation
>;
