import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import { addNotification, NotificationType } from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import getNonDuplicateRelations from "../../helpers/getNonDuplicateRelations/getNonDuplicateRelations";
import { ForbiddenRelationsFormValues } from "../../types";
import AddForbiddenRelationsPopup from "../addForbiddenRelationsPopup/AddForbiddenRelationsPopup";

type Props = {
  isUpdate: boolean;
};

const AddForbiddenRelationsButton: React.FC<Props> = ({ isUpdate }) => {
  const { formatMessage } = useIntl();

  const [showAddPopup, setShowAddPopup] = React.useState(false);

  const [, meta, helper] =
    useField<Array<ForbiddenRelationsFormValues>>("forbiddenRelations");

  return (
    <div>
      <Button onClick={() => setShowAddPopup(true)}>
        <Icon InlineIcon={IconPlus} />
        <FormattedMessage id="lineForm.forbiddenRelations.label.add" />
      </Button>

      {showAddPopup && (
        <AddForbiddenRelationsPopup
          onConfirm={(newRelations: Array<ForbiddenRelationsFormValues>) => {
            const relationsToAdd = getNonDuplicateRelations(meta.value, newRelations);

            if (newRelations.length && newRelations.length > relationsToAdd.length) {
              addNotification({
                type: NotificationType.Warning,
                message: formatMessage({
                  id: "lineForm.forbiddenRelations.info.duplicate",
                }),
              });
            }

            if (isUpdate && relationsToAdd.length) {
              addNotification({
                message: formatMessage({
                  id: "lineForm.forbiddenRelations.notification.update",
                }),
              });
            }

            helper.setValue([...relationsToAdd, ...meta.value]);
            setShowAddPopup(false);
          }}
          onCancel={() => setShowAddPopup(false)}
        />
      )}
    </div>
  );
};

export default AddForbiddenRelationsButton;
