import { Input, SelectGroup } from "@flixbus/honeycomb-react";
import { ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import cx from "classnames";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import getUnitText from "../../helpers/getUnitText/getUnitText";
import isValid from "../../helpers/validateFormikInput/validateFormikInput";
import { TimeUnit } from "../../types/schema";
import * as css from "./FormikTimeUnitInput.scss";

type FormikTimeUnitInputProps = {
  name: string;
  label: string;
  defaultUnit: TimeUnit;
  optionalUnit: TimeUnit;
  readOnly?: boolean;
  info?: string;
  maxLength?: number;
  required?: boolean;
};

const FormikTimeUnitInput: React.FC<FormikTimeUnitInputProps> = ({
  name,
  label,
  defaultUnit,
  optionalUnit,
  readOnly,
  info,
  maxLength,
  required,
}) => {
  const [fieldValue, metaValue, helperValue] = useField(`${name}.value`);
  const [fieldUnit, metaUnit, helperUnit] = useField(`${name}.unit`);

  const [focused, setFocused] = React.useState(false);

  const handleChange = (value: TimeUnit) => {
    helperUnit.setValue(value);
    // https://github.com/formium/formik/issues/2083#issuecomment-571259235
    helperUnit.setTouched(true, false);
    helperValue.setTouched(true);
  };

  const options = [
    {
      id: `${name}-${defaultUnit}`,
      value: defaultUnit,
      name: `${name}unit`,
      label: getUnitText(defaultUnit),
      checked: metaUnit.value === undefined || metaUnit.value === defaultUnit,
      onChange: () => handleChange(defaultUnit),
    },
    {
      id: `${name}-${optionalUnit}`,
      value: optionalUnit,
      name: `${name}unit`,
      label: getUnitText(optionalUnit),
      checked: metaUnit.value === optionalUnit,
      onChange: () => handleChange(optionalUnit),
    },
  ];

  const isValidInput = isValid(metaValue);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {metaValue.value ? (
          `${metaValue.value} ${getUnitText(metaUnit.value)}`
        ) : (
          <FormattedMessage id="general.notAvailable" />
        )}
      </ReadOnlyFormField>
    );
  }

  const LABEL = label ? `${label}${required ? " *" : ""}` : label;

  return (
    <div>
      <div data-id="unitInput-label" className={css.unitInputLabel}>
        {LABEL}
      </div>
      <div className={css.wrapper}>
        <Input
          id={`${name}-input`}
          type="number"
          aria-label={LABEL}
          extraClasses={css.input}
          onFocus={() => setFocused(true)}
          valid={isValidInput}
          maxLength={maxLength}
          {...fieldValue}
          onBlur={() => {
            setFocused(false);
            helperValue.setTouched(true);
          }}
          onChange={(event) => {
            if (event.target.value !== "") {
              helperValue.setValue(event.target.valueAsNumber);
            } else {
              helperValue.setValue(event.target.value);
            }
          }}
        />

        <SelectGroup
          aria-label="unit-select"
          options={options}
          extraClasses={cx(
            css.selectGroup,
            focused && css.selectGroupOutlineActive,
            isValidInput === false && !focused && css.selectGroupOutlineError
          )}
          {...fieldUnit}
        />
      </div>

      {isValidInput === false && metaValue.error && (
        <>
          <div data-id="error-text" className={`${css.errorText} ${css.finePrint}`}>
            {metaValue.error}
          </div>
        </>
      )}
      {info && (
        <div data-id="info-text" className={css.finePrint}>
          {info}
        </div>
      )}
    </div>
  );
};

export default FormikTimeUnitInput;
