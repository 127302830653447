import {
  getPeriodAsString,
  PeriodPicker,
  ReadOnlyFormField,
} from "@flixbus-phx/marketplace-common";
import { addYears, startOfTomorrow } from "date-fns";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";

export type FormikPeriodSelectProps = {
  name: string;
  label: string;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
};

const FormikPeriodSelect: React.FC<FormikPeriodSelectProps> = ({
  name,
  label,
  placeholder,
  readOnly,
  required,
}) => {
  const [, { value: start, error: errorStart, touched: touchedStart }, helperStart] =
    useField(`${name}.start`);
  const [, { value: end, error: errorEnd, touched: touchedEnd }, helperEnd] = useField(
    `${name}.end`
  );

  const [, , helper] = useField(name);

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {start && end ? (
          getPeriodAsString(start, end)
        ) : (
          <FormattedMessage id="general.notAvailable" />
        )}
      </ReadOnlyFormField>
    );
  }

  return (
    <PeriodPicker
      inputElement="input"
      earliestStartDate={startOfTomorrow()}
      latestEndDate={addYears(startOfTomorrow(), 3)}
      periodStart={start}
      periodEnd={end}
      onChange={(selectedStart, selectedEnd) => {
        helper.setValue({ start: selectedStart, end: selectedEnd });
        helperStart.setTouched(true);
        helperEnd.setTouched(true);
        helper.setTouched(true);
      }}
      onFocus={() => {
        helperStart.setTouched(true);
        helperEnd.setTouched(true);
      }}
      label={label}
      errorMessage={
        errorStart && touchedStart
          ? errorStart
          : errorEnd && touchedEnd
            ? errorEnd
            : undefined
      }
      placeholder={placeholder}
      required={required}
    />
  );
};

export default FormikPeriodSelect;
