import { Grid, GridCol, Skeleton } from "@flixbus/honeycomb-react";
import * as React from "react";
import Layout from "../../../../ui/Layout";
import ScheduleHeaderWrapper from "../../../scheduleHeader/ui/scheduleHeaderWrapper/ScheduleHeaderWrapper";
import * as css from "./ScheduleDetailSkeleton.scss";

type Props = {
  scheduleId: string;
};

const ScheduleDetailSkeleton: React.FC<Props> = ({ scheduleId }) => {
  return (
    <>
      <ScheduleHeaderWrapper scheduleId={scheduleId}>
        <Grid>
          <GridCol size={11}>
            <Skeleton height="lg" extraClasses={css.nameSkeleton} />
          </GridCol>
          <GridCol size={1}>
            <Skeleton height="lg" extraClasses={css.buttonSkeleton} />
          </GridCol>
        </Grid>
      </ScheduleHeaderWrapper>
      <Layout noTopPadding>
        <Skeleton width="lg" height="sm" />
      </Layout>
    </>
  );
};

export default ScheduleDetailSkeleton;
