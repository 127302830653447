import { Checkbox } from "@flixbus/honeycomb-react";
import { useField } from "formik";
import * as React from "react";

type Props = {
  label: string;
  name: string;
  readOnly?: boolean;
  small?: boolean;
};

const FormikCheckbox: React.FC<Props> = ({ label, name, readOnly, small }) => {
  const [, meta, helper] = useField<boolean>(name);

  const selectedValue = meta.value;

  return (
    <Checkbox
      id={name}
      value={name}
      label={label}
      onChange={() => helper.setValue(!selectedValue)}
      checked={selectedValue}
      disabled={readOnly}
      small={small}
    />
  );
};

export default FormikCheckbox;
