import { Heading, Text } from "@flixbus/honeycomb-react";
import { readURIParams, ErrorPage, ErrorTypes } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import CreateSchedule from "../../schedule/createSchedule/CreateSchedule";
import Layout from "../../ui/Layout";
import * as css from "./CreateScheduleView.scss";

const CreateScheduleView: React.FC = () => {
  const { search } = useLocation();
  const { line } = readURIParams(search);

  return (
    <>
      {line ? (
        <Layout>
          <Heading size={1}>
            <FormattedMessage id="schedule.create" />
          </Heading>
          <Text extraClasses={css.text}>
            <FormattedMessage id="schedule.create.description" />
          </Text>
          <CreateSchedule lineId={line} />
        </Layout>
      ) : (
        <ErrorPage type={ErrorTypes.e404} />
      )}
    </>
  );
};

export default CreateScheduleView;
