import { Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import FormikSelect from "../../shared/components/formikSelect/FormikSelect";
import { PartnerEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import meansOfTransPortOptions from "../../shared/helpers/meansOfTransPortOptions/meansOfTransportOptions";
import * as css from "../../shared/styles/heading.scss";
import { AccessPackage, AccountInput, Currency } from "../../shared/types/schema";
import BusinessRegionSelect from "./containers/businessRegionSelect/BusinessRegionSelect";
import NameAndConcessionOwnerIdInput from "./containers/nameAndConcessionOwnerIdInput/NameAndConcessionOwnerIdInput";
import validationSchema from "./helpers/validationSchema/validationSchema";
import BDInput from "./ui/bdInput/BDInput";
import ReportingCurrencySelect from "./ui/reportingCurrencySelect/ReportingCurrencySelect";

export type PartnerAccountFormProps = {
  onSubmit: (values: AccountInput) => void;
  onCancel: () => void;
  defaultValues?: Partial<AccountInput>;
  editableFields?: PartnerEditableFields["accountInput"];
  isCreate: boolean;
};

const PartnerAccountForm: React.FC<PartnerAccountFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  editableFields,
  isCreate,
  children,
}) => {
  const { formatMessage } = useIntl();
  const initialValues: AccountInput = {
    name: "",
    businessDevelopers: [{ name: "", email: "" }],
    businessRegionId: "",
    ...defaultValues,
    concessionOwnerId: defaultValues?.concessionOwnerId
      ? defaultValues.concessionOwnerId
      : "",
    accessPackage: defaultValues?.accessPackage
      ? defaultValues.accessPackage
      : AccessPackage.Light,
    currency: defaultValues?.currency ? defaultValues?.currency : ("" as Currency),
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          meansOfTransport: values.meansOfTransport ? values.meansOfTransport : null,
        });
      }}
      onReset={onCancel}
    >
      <Form noValidate>
        <Grid>
          <GridCol size={12}>
            <Heading data-id="partner-detail-heading" size={4} extraClasses={css.heading}>
              <FormattedMessage id="partnerForm.partnerDetails.headline" />
            </Heading>
          </GridCol>
          <GridCol size={editableFields?.concessionOwnerId?.isShown ? 12 : 6}>
            <CustomFormRow>
              <NameAndConcessionOwnerIdInput
                readOnlyName={!editableFields?.name}
                readOnlyConcessionOwnerId={!editableFields?.concessionOwnerId?.isEditable}
                isConcessionOwnerIdShown={editableFields?.concessionOwnerId?.isShown}
              />
            </CustomFormRow>
          </GridCol>
          {editableFields?.businessRegionId.isShown ? (
            <GridCol size={6}>
              <CustomFormRow>
                <BusinessRegionSelect
                  readOnly={!editableFields?.businessRegionId.isEditable}
                />
              </CustomFormRow>
            </GridCol>
          ) : (
            <></>
          )}
          <GridCol size={6}>
            <CustomFormRow>
              <FormikSelect
                label={formatMessage({ id: "partnerForm.meansOfTransport.label" })}
                readOnly={!editableFields?.meansOfTransport}
                name="meansOfTransport"
                options={meansOfTransPortOptions()}
                placeholder={formatMessage({
                  id: "partnerForm.meansOfTransport.placeholder",
                })}
                isClearable
              />
            </CustomFormRow>
          </GridCol>

          <GridCol size={6}>
            <CustomFormRow>
              <FormikSelect
                label={formatMessage({ id: "partnerForm.accessPackage.label" })}
                readOnly={!editableFields?.accessPackage}
                name="accessPackage"
                required
                options={[
                  { label: "View", value: AccessPackage.View },
                  { label: "Light", value: AccessPackage.Light },
                  { label: "Pro", value: AccessPackage.Pro },
                ]}
              />
            </CustomFormRow>
          </GridCol>
          <GridCol size={6}>
            <CustomFormRow>
              <ReportingCurrencySelect
                readOnly={!editableFields?.currency}
                required
                isCreate={isCreate}
              />
            </CustomFormRow>
          </GridCol>
          <GridCol size={12}>
            <CustomFormRow data-id="bd-input">
              <Heading size={4} extraClasses={css.heading}>
                <FormattedMessage id="partnerForm.bdInput.headline" />
              </Heading>
              <BDInput readOnly={!editableFields?.businessDevelopers} />
            </CustomFormRow>
          </GridCol>
        </Grid>

        {children}
      </Form>
    </Formik>
  );
};

export default PartnerAccountForm;
