import { Heading } from "@flixbus/honeycomb-react";
import {
  addNotification,
  Feature,
  formatErrorMessage,
  hasUserPermission,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import StationSelect from "../../../../shared/components/stationSelect/StationSelect";
import { selectedStationVar } from "../../../../state/reactiveVariables/reactiveVariables";
import useSelectedStation from "../../../../state/useSelectedStation/useSelectedStation";
import {
  FindScheduleWithTripsAndStationsQuery,
  StationSequenceItemFragment,
  useAddStationToScheduleMutation,
  useRemoveStationFromScheduleMutation,
} from "../../api/operations.generated";
import StationCell from "../../ui/stationCell/StationCell";
import TimetableColumn from "../../ui/timetableColumn/TimetableColumn";
import * as css from "./StationColumn.scss";

type Props = {
  scheduleId: FindScheduleWithTripsAndStationsQuery["findSchedule"]["id"];
  readOnly: boolean;
  stationSequence: Array<StationSequenceItemFragment>;
  headline: string;
};

const StationColumn: React.FC<Props> = ({
  scheduleId,
  readOnly = false,
  stationSequence,
  headline,
}) => {
  const { formatMessage } = useIntl();
  const [addStationToSchedule] = useAddStationToScheduleMutation({
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to add a station to this schedule: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });

  const handleAddStation = (stationId: string, index?: number | undefined) => {
    addStationToSchedule({
      variables: { scheduleId, stationId, index },
    });
  };

  const [removeStationFromSchedule] = useRemoveStationFromScheduleMutation({
    onError: (error) => {
      addNotification({
        message: `Sorry, we are unable to remove this station from the schedule: ${formatErrorMessage(
          error.message
        )}`,
        type: NotificationType.Danger,
      });
    },
  });

  const handleRemoveStation = (stationId: string) => {
    removeStationFromSchedule({
      variables: { scheduleId, stationId },
    });
  };

  const [, changeSelectedStation] = useSelectedStation(selectedStationVar);

  const handleStationDetailRequested = (stationId: string) => {
    changeSelectedStation(stationId);
  };

  const disallowedValues = stationSequence.map((station) => station.id);

  const createStationCells = (
    stations: Array<StationSequenceItemFragment>
  ): Array<JSX.Element> => {
    const stationCells = stations.map((station, index, arr): JSX.Element => {
      return (
        <StationCell
          key={`${station.id}`}
          station={station}
          isFirstOrLast={index === 0 || index === stations.length - 1}
          readOnly={readOnly}
          onStationClicked={() => handleStationDetailRequested(station.id)}
          onStationRemove={() => handleRemoveStation(station.id)}
          onStationAdd={(stationId) => handleAddStation(stationId, index)}
          isCountryChange={
            index > 0 && station.countryCode !== arr[index - 1].countryCode
          }
          disallowedValues={disallowedValues}
        />
      );
    });

    return [
      <Heading size={1} extraClasses={css.headline}>
        {headline}
      </Heading>,
    ].concat(stationCells);
  };

  const addNewStationCell = (): Array<JSX.Element> => {
    return [
      <div className={css.container}>
        <StationSelect
          label={formatMessage({ id: "timetable.addStation.label" })}
          onStationSelected={(stationId) => {
            handleAddStation(stationId);
          }}
          disallowedValues={disallowedValues}
          createStationPossible={hasUserPermission(Feature.CREATE_STATION)}
        />
      </div>,
    ];
  };

  return (
    <>
      <TimetableColumn
        data-id="station-cells"
        content={createStationCells(stationSequence)}
        colPosition={1}
        rowPosition={1}
        extraClasses={css.stations}
        childClasses={css.cells}
      />
      {!readOnly && (
        <TimetableColumn
          data-id="station-cell"
          content={addNewStationCell()}
          colPosition={1}
          rowPosition={2}
          extraClasses={css.addNewStation}
          childClasses={css.addNewStationChildren}
        />
      )}
    </>
  );
};

export default StationColumn;
