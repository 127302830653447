import { IconArrowLeft } from "@flixbus/honeycomb-icons-react";
import { addNotification, NotificationType } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import buildPath from "../../routes/buildPath/buildPath";
import FormikButtons from "../../shared/components/formikButtons/FormikButtons";
import getEditableScheduleFields from "../../shared/helpers/getEditableFormFields/getEditableScheduleFields/getEditableScheduleFields";
import ScheduleForm from "../scheduleForm/ScheduleForm";
import * as css from "./CreateSchedule.scss";
import {
  NewScheduleMutationVariables,
  useFindLineDetailsForScheduleCreationQuery,
  useNewScheduleMutation,
} from "./api/operations.generated";

type Props = {
  lineId: string;
};

const CreateSchedule: React.FC<Props> = ({ lineId }) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { data } = useFindLineDetailsForScheduleCreationQuery({
    variables: {
      id: lineId,
    },
  });

  const [addSchedule, { loading: mutationLoading }] = useNewScheduleMutation({
    onError: (error) => {
      addNotification({
        message: error.message,
        type: NotificationType.Danger,
      });
    },
    onCompleted: ({ newSchedule }) => {
      navigate(buildPath.showScheduleDetail(newSchedule.id));
    },
  });

  const handleCreateSchedule = (values: Omit<NewScheduleMutationVariables, "lineId">) => {
    addSchedule({ variables: { ...values, lineId } });
  };

  if (data?.findLine) {
    return (
      <div className={css.wrapper}>
        <ScheduleForm
          onSubmit={handleCreateSchedule}
          onCancel={() => navigate(buildPath.showLineDetail(lineId))}
          partnerId={data.findLine.partner.id}
          defaultValues={{ circulationId: data.findLine.defaultCirculation?.uuid }}
          editableFields={getEditableScheduleFields(true, false)}
        >
          <FormikButtons
            submitButtonText={formatMessage({ id: "schedule.create" })}
            submitButtonAppearance="primary"
            submitButtonLoading={mutationLoading}
            resetButtonText={formatMessage({ id: "general.back" })}
            resetButtonAppearance={undefined}
            resetButtonIcon={IconArrowLeft}
            disableSubmitWhenNotDirty
          />
        </ScheduleForm>
      </div>
    );
  }

  return <></>;
};

export default CreateSchedule;
