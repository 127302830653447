import { Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { FormikAutocomplete, timezones } from "@flixbus-phx/marketplace-common";
import { Form, Formik } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../shared/components/formikInput/FormikInput";
import useNearbyStations from "../../shared/hooks/useNearbyStations/useNearbyStations";
import { MutationNewStationArgs } from "../../shared/types/schema";
import NearbyStationPopup from "../nearbyStationPopup/NearbyStationPopup";
import * as css from "./StationForm.scss";
import CityAndCountrySelect from "./containers/cityAndCountrySelect/CityAndCountrySelect";
import getInitialValues from "./helpers/getInitialValues/getInitialValues";
import getSubmittableTransferOpeningHours from "./helpers/getSubmittableTransferOpeningHours/getSubmittableTransferOpeningHours";
import validationSchema from "./helpers/validationSchema/validationSchema";
import { StationFormDefaultValues, StationFormValues, TransferTime } from "./types";
import AddressLinesInput from "./ui/addressLinesInput/AddressLinesInput";
import GeoCoordinatesInput from "./ui/geoCoordinatesInput/GeoCoordinatesInput";
import NearbyStationsWarningBox from "./ui/nearbyStationsWarningBox/NearbyStationsWarningBox";
import TransferOpeningHoursInput from "./ui/transferOpeningHoursInput/TransferOpeningHoursInput";

export type StationFormProps = {
  onSubmit: (values: MutationNewStationArgs) => void;
  onCancel: () => void;
  stationName?: string;
  defaultValues?: StationFormDefaultValues;
  readOnly?: boolean;
  submitLoading: boolean;
  hasNearbyStation?: boolean;
};

const StationForm: React.FC<StationFormProps> = ({
  onSubmit,
  onCancel,
  stationName,
  defaultValues,
  readOnly = false,
  children,
  submitLoading,
  hasNearbyStation = false,
}) => {
  const { formatMessage } = useIntl();

  const [nearbyStations, showNearbyStations, findNearbyStations, setShowNearbyStations] =
    useNearbyStations();

  const isInitialShortNameEqualName = defaultValues
    ? defaultValues.shortName === defaultValues.name
    : true;

  const submit = (values: StationFormValues) => {
    const { isTransferStation } = values.transferInformation;

    const submittableValues = {
      ...values,
      shortName: isInitialShortNameEqualName ? values.name : values.shortName,
      code: values.code?.toUpperCase() || undefined,
      countryCode: values.countryCode,
      transferInformation: {
        isTransferStation,
        minimumTransferMinutes:
          isTransferStation &&
          values.transferInformation.transferTime === TransferTime.FIXED
            ? values.transferInformation.minimumTransferMinutes
            : undefined,
        transferOpeningHours: getSubmittableTransferOpeningHours(
          values.transferInformation
        ),
      },
      addressLines: values.addressLines.filter((line) => line !== ""),
    };

    onSubmit(submittableValues);
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={getInitialValues(stationName, defaultValues)}
      onSubmit={(values) => {
        if (
          (defaultValues?.latitude !== values.latitude ||
            defaultValues.longitude !== values.longitude) &&
          !showNearbyStations
        ) {
          findNearbyStations(values.latitude, values.longitude, () => {
            submit(values);
          });
        } else {
          submit(values);
        }
      }}
      onReset={onCancel}
    >
      {({ values, handleSubmit }) => {
        if (showNearbyStations && nearbyStations?.length) {
          return (
            <NearbyStationPopup
              stations={nearbyStations}
              confirmButtonText={formatMessage({
                id: `nearbyStations.popup.confirmButtonText.${
                  defaultValues ? "variationTwo" : "variationThree"
                }`,
              })}
              onSubmit={() => {
                handleSubmit();
              }}
              onCancel={() => setShowNearbyStations(false)}
              confirmButtonLoading={submitLoading}
              latitude={values.latitude}
              longitude={values.longitude}
              isRenderedInsidePopup={!defaultValues}
            />
          );
        }

        return (
          <Form noValidate>
            <CustomFormRow>
              {readOnly ? (
                defaultValues?.name ? (
                  <Heading size={2}>{defaultValues?.name}</Heading>
                ) : (
                  <></>
                )
              ) : (
                <FormikInput
                  name="name"
                  label={formatMessage({ id: "station.name.input.label" })}
                  placeholder={formatMessage({ id: "station.name.input.placeholder" })}
                  type="text"
                  required
                  readOnly={readOnly}
                />
              )}
            </CustomFormRow>
            {!isInitialShortNameEqualName && (
              <CustomFormRow>
                <FormikInput
                  name="shortName"
                  label={formatMessage({ id: "station.stationShortName" })}
                  type="text"
                  readOnly={readOnly}
                />
              </CustomFormRow>
            )}
            <CustomFormRow>
              <Grid>
                <GridCol size={6}>
                  <FormikAutocomplete
                    name="timezone"
                    label={formatMessage({ id: "timezone.select.label" })}
                    placeholder={formatMessage({ id: "station.timeZone.info" })}
                    options={timezones()}
                    required
                    readOnly={readOnly}
                    startsOnInputValueLength={0}
                  />
                </GridCol>
                <GridCol size={6} extraClasses={css.codeInput}>
                  <FormikInput
                    name="code"
                    label={formatMessage({ id: "station.stationCode" })}
                    placeholder="NYC"
                    type="text"
                    readOnly={readOnly}
                  />
                </GridCol>
              </Grid>
            </CustomFormRow>
            <CustomFormRow>
              <CityAndCountrySelect readOnly={readOnly} />
            </CustomFormRow>
            <CustomFormRow>
              <GeoCoordinatesInput readOnly={readOnly} />
            </CustomFormRow>
            {hasNearbyStation && defaultValues?.latitude && defaultValues?.longitude && (
              <NearbyStationsWarningBox
                latitude={defaultValues.latitude}
                longitude={defaultValues.longitude}
              />
            )}
            <Heading size={4}>
              <FormattedMessage id="general.address" />
            </Heading>
            <AddressLinesInput readOnly={readOnly} />
            <CustomFormRow>
              <Grid>
                <GridCol size={6}>
                  <FormikInput
                    name="zipCode"
                    label={formatMessage({ id: "station.zipCode" })}
                    placeholder="86152"
                    type="text"
                    readOnly={readOnly}
                    required
                  />
                </GridCol>
                <GridCol size={6}>
                  <FormikInput
                    name="addressCity"
                    label={formatMessage({ id: "station.addressCity" })}
                    placeholder="New York City"
                    type="text"
                    readOnly={readOnly}
                    required
                  />
                </GridCol>
              </Grid>
            </CustomFormRow>
            <TransferOpeningHoursInput readOnly={readOnly} />
            {children}
          </Form>
        );
      }}
    </Formik>
  );
};

export default StationForm;
