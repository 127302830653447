import { Button, ButtonGroup, FormRow } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { useFormikContext } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./StationFormButtons.scss";

export type StationFormButtonsProps = {
  submitButtonText: string;
  usedInPanel?: boolean;
  readOnly?: boolean;
  hideCloseButton?: boolean;
  submitLoading: boolean;
};

const StationFormButtons: React.FC<StationFormButtonsProps> = ({
  submitButtonText,
  usedInPanel = false,
  readOnly,
  hideCloseButton,
  submitLoading,
}) => {
  const { dirty } = useFormikContext();

  return (
    <FormRow>
      <div data-id="button-wrapper" className={css.buttonWrapper}>
        <ButtonGroup alignment="center" extraClasses={css.buttonGroup}>
          {readOnly ? (
            hideCloseButton ? (
              <></>
            ) : (
              <Button
                type="reset"
                appearance="primary"
                extraClasses={cx(usedInPanel && css.panelButton)}
              >
                <FormattedMessage id="general.close" />
              </Button>
            )
          ) : (
            <>
              <Button type="reset" extraClasses={cx(usedInPanel && css.panelButton)}>
                <FormattedMessage id="general.cancel" />
              </Button>

              <Button
                data-id="station-submit-button"
                type="submit"
                disabled={!dirty}
                appearance="primary"
                extraClasses={cx(usedInPanel && css.panelButton)}
                loading={submitLoading}
              >
                {submitButtonText}
              </Button>
            </>
          )}
        </ButtonGroup>
      </div>
    </FormRow>
  );
};

export default StationFormButtons;
