// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type NewScheduleMutationVariables = Types.Exact<{
  lineId: Types.Scalars["ID"]["input"];
  name: Types.Scalars["String"]["input"];
  period: Types.SchedulePeriodInput;
  nonOperatingPeriods?: Types.InputMaybe<
    Array<Types.NonOperatingPeriodInput> | Types.NonOperatingPeriodInput
  >;
  circulationId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
}>;

export type NewScheduleMutation = {
  __typename?: "Mutation";
  newSchedule: { __typename?: "Schedule"; id: string };
};

export type FindLineDetailsForScheduleCreationQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type FindLineDetailsForScheduleCreationQuery = {
  __typename?: "Query";
  findLine: {
    __typename?: "Line";
    id: string;
    partner: { __typename?: "Partner"; id: string };
    defaultCirculation?: { __typename?: "Circulation"; id: string; uuid: string } | null;
  };
};

export const NewScheduleDocument = gql`
  mutation NewSchedule(
    $lineId: ID!
    $name: String!
    $period: SchedulePeriodInput!
    $nonOperatingPeriods: [NonOperatingPeriodInput!]
    $circulationId: String
  ) {
    newSchedule(
      name: $name
      lineId: $lineId
      period: $period
      nonOperatingPeriods: $nonOperatingPeriods
      circulationId: $circulationId
    ) {
      id
    }
  }
`;

/**
 * __useNewScheduleMutation__
 *
 * To run a mutation, you first call `useNewScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNewScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [newScheduleMutation, { data, loading, error }] = useNewScheduleMutation({
 *   variables: {
 *      lineId: // value for 'lineId'
 *      name: // value for 'name'
 *      period: // value for 'period'
 *      nonOperatingPeriods: // value for 'nonOperatingPeriods'
 *      circulationId: // value for 'circulationId'
 *   },
 * });
 */
export function useNewScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    NewScheduleMutation,
    NewScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<NewScheduleMutation, NewScheduleMutationVariables>(
    NewScheduleDocument,
    options
  );
}
export type NewScheduleMutationHookResult = ReturnType<typeof useNewScheduleMutation>;
export const FindLineDetailsForScheduleCreationDocument = gql`
  query findLineDetailsForScheduleCreation($id: ID!) {
    findLine(id: $id) {
      id
      partner {
        id
      }
      defaultCirculation {
        id
        uuid
      }
    }
  }
`;

/**
 * __useFindLineDetailsForScheduleCreationQuery__
 *
 * To run a query within a React component, call `useFindLineDetailsForScheduleCreationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindLineDetailsForScheduleCreationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindLineDetailsForScheduleCreationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindLineDetailsForScheduleCreationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    FindLineDetailsForScheduleCreationQuery,
    FindLineDetailsForScheduleCreationQueryVariables
  > &
    (
      | { variables: FindLineDetailsForScheduleCreationQueryVariables; skip?: boolean }
      | { skip: boolean }
    )
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    FindLineDetailsForScheduleCreationQuery,
    FindLineDetailsForScheduleCreationQueryVariables
  >(FindLineDetailsForScheduleCreationDocument, options);
}
export function useFindLineDetailsForScheduleCreationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FindLineDetailsForScheduleCreationQuery,
    FindLineDetailsForScheduleCreationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    FindLineDetailsForScheduleCreationQuery,
    FindLineDetailsForScheduleCreationQueryVariables
  >(FindLineDetailsForScheduleCreationDocument, options);
}
export function useFindLineDetailsForScheduleCreationSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        FindLineDetailsForScheduleCreationQuery,
        FindLineDetailsForScheduleCreationQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    FindLineDetailsForScheduleCreationQuery,
    FindLineDetailsForScheduleCreationQueryVariables
  >(FindLineDetailsForScheduleCreationDocument, options);
}
export type FindLineDetailsForScheduleCreationQueryHookResult = ReturnType<
  typeof useFindLineDetailsForScheduleCreationQuery
>;
export type FindLineDetailsForScheduleCreationLazyQueryHookResult = ReturnType<
  typeof useFindLineDetailsForScheduleCreationLazyQuery
>;
export type FindLineDetailsForScheduleCreationSuspenseQueryHookResult = ReturnType<
  typeof useFindLineDetailsForScheduleCreationSuspenseQuery
>;
