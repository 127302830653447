import { Grid, GridCol, List, SelectGroup } from "@flixbus/honeycomb-react";
import { useField } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import * as css from "./LineCodeInput.scss";

type Props = {
  readOnly?: boolean;
  hideSelectGroup: boolean;
};

export enum LineCodeOption {
  Auto = "AUTO",
  Custom = "CUSTOM",
}

const LineCodeInput: React.FC<Props> = ({ readOnly, hideSelectGroup }) => {
  const { formatMessage } = useIntl();

  const [, metaOption, helperOption] = useField<LineCodeOption>("networkCodeType");
  const [, , helperCode] = useField<string>("customNetworkCode");

  React.useEffect(() => {
    if (metaOption.value === LineCodeOption.Auto) {
      helperCode.setValue("");
      helperCode.setTouched(false);
      helperCode.setError(undefined);
    }
  }, [metaOption.value]);

  const options = [
    {
      id: LineCodeOption.Auto,
      value: LineCodeOption.Auto,
      name: "lineCodeOption",
      label: formatMessage({ id: "lineForm.lineCode.select.option.auto" }),
      onClick: () => {
        helperOption.setValue(LineCodeOption.Auto);
      },
      defaultChecked: metaOption.value === LineCodeOption.Auto,
    },
    {
      id: LineCodeOption.Custom,
      value: LineCodeOption.Custom,
      name: "lineCodeOption",
      label: formatMessage({ id: "lineForm.lineCode.select.option.custom" }),
      onClick: () => {
        helperOption.setValue(LineCodeOption.Custom);
      },
      defaultChecked: metaOption.value === LineCodeOption.Custom,
    },
  ];

  return (
    <>
      <Grid>
        {!hideSelectGroup ? (
          <GridCol size={4}>
            <SelectGroup
              label={formatMessage({ id: "lineForm.lineCode.select.label" })}
              options={options}
              disabled={readOnly}
              info={formatMessage({ id: "lineForm.lineCode.select.option.info" })}
            />
          </GridCol>
        ) : (
          <></>
        )}
        {metaOption.value === LineCodeOption.Custom || hideSelectGroup ? (
          <GridCol size={4} extraClasses={css.forceUppercase}>
            <FormikInput
              type="text"
              label={
                hideSelectGroup
                  ? formatMessage({ id: "lineForm.lineCode.select.label" })
                  : formatMessage({ id: "lineForm.lineCode.input.label" })
              }
              name="customNetworkCode"
              readOnly={readOnly}
              placeholder="ABC00001"
              required
              tooltipContent={
                <List extraClasses={css.list}>
                  <li>
                    <FormattedMessage id="lineForm.lineCode.input.tooltip.firstLine" />
                  </li>
                  <li>
                    <FormattedMessage id="lineForm.lineCode.input.tooltip.secondLine" />
                  </li>
                  <li>
                    <FormattedMessage id="lineForm.lineCode.input.tooltip.thirdLine" />
                  </li>
                  <li>
                    <FormattedMessage id="lineForm.lineCode.input.tooltip.fourthLine" />
                  </li>
                </List>
              }
            />
          </GridCol>
        ) : (
          <></>
        )}
      </Grid>
    </>
  );
};

export default LineCodeInput;
