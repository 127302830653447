import { Fieldset, Heading, Radio } from "@flixbus/honeycomb-react";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import InterconnectionOnlyText from "../../../../shared/components/inventoryRestrictionLabels/interconnectionOnlyText/InterconnectionOnlyText";
import StandaloneOnlyText from "../../../../shared/components/inventoryRestrictionLabels/standaloneOnlyText/StandaloneOnlyText";
import {
  InterconnectionRuleType,
  InventoryRestriction,
  Operator,
} from "../../../../shared/types/schema";
import NoMobilityPartnerText from "../noMobilityPartnerText/NoMobilityPartnerText";

type Props = {
  hasMobilityPartner?: boolean;
};

const InventoryRestrictionSelect: React.FC<Props> = ({ hasMobilityPartner }) => {
  const { formatMessage } = useIntl();
  const [, metaInventoryRestriction, helperInventoryRestriction] =
    useField<InventoryRestriction>("inventoryRule.inventoryRestriction");
  const [, , helperInterconnectionRule] = useField<InterconnectionRuleType>(
    "inventoryRule.interconnectionRule.type"
  );
  const [, , helperOperator] = useField<Array<Operator>>(
    "inventoryRule.interconnectionRule.operators"
  );

  const handleSelect = (option: InventoryRestriction) => {
    if (metaInventoryRestriction.value !== option) {
      helperInventoryRestriction.setValue(option);

      if (option === InventoryRestriction.StandaloneOnly) {
        helperInterconnectionRule.setValue(InterconnectionRuleType.None);
        helperOperator.setValue([]);
      }
    }
  };

  const radioOptions = [
    {
      label: formatMessage({ id: "partnerForm.inventoryRules.option.none" }),
      value: InventoryRestriction.None,
    },
    {
      label: <InterconnectionOnlyText />,
      value: InventoryRestriction.InterconnectionOnly,
    },
    {
      label: <StandaloneOnlyText />,
      value: InventoryRestriction.StandaloneOnly,
    },
  ];

  return (
    <>
      <Heading size={4}>
        <FormattedMessage id="partnerForm.inventoryRules.label" />
      </Heading>
      {hasMobilityPartner ? (
        <CustomFormRow>
          <Fieldset itemSpacing="2">
            {radioOptions.map((option) => (
              <div key={`inventory-restriction-radio-${option.value}`}>
                <Radio
                  id={`inventory-restriction-${option.value}`}
                  name="inventory-restriction"
                  label={option.label}
                  value={option.value}
                  key={`inventory-restriction-${option.value}`}
                  onClick={() => handleSelect(option.value)}
                  defaultChecked={metaInventoryRestriction.value === option.value}
                />
              </div>
            ))}
          </Fieldset>
        </CustomFormRow>
      ) : (
        <NoMobilityPartnerText />
      )}
    </>
  );
};

export default InventoryRestrictionSelect;
