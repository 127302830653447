import { Icon, IconDelete, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import { FindAllCountriesQuery } from "../../api/operations.generated";
import * as css from "./CountryApproverForm.scss";
import validationSchema from "./validationSchema/validationSchema";

type ApproversArray = FindAllCountriesQuery["findAllCountries"][number]["approver"];

export type CountryApproverFormProps = {
  defaultValues: ApproversArray;
  readOnly: boolean;
  onSubmit: (newValues: ApproversArray) => void;
  onCancel: () => void;
  submitLoading: boolean;
};

const CountryApproverForm: React.FC<CountryApproverFormProps> = ({
  defaultValues,
  readOnly,
  onSubmit,
  onCancel,
  submitLoading,
}) => {
  const { formatMessage } = useIntl();

  React.useEffect(() => {
    // Autofocus the last input when form is loading
    // Skipping this for testing as I have no idea how to mock it
    if (!readOnly && process.env.NODE_ENV !== "test") {
      document
        .getElementsByName(
          `approver[${defaultValues.length ? defaultValues.length - 1 : 0}]`
        )[0]
        .focus();
    }
  }, [readOnly]);

  if (readOnly) {
    return (
      <>
        {defaultValues.map((contact) => (
          <div data-id="readOnly-contact" key={contact}>
            {contact}
          </div>
        ))}
      </>
    );
  }

  const removeDuplicatesAndEmptyStrings = (values: ApproversArray): ApproversArray => {
    return Array.from(new Set(values.filter((value) => value.trim() !== "")));
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={{ approver: defaultValues.length ? defaultValues : [""] }}
      onSubmit={(values) => {
        onSubmit(removeDuplicatesAndEmptyStrings(values.approver));
      }}
      onReset={onCancel}
    >
      {({ values, setFieldValue, setValues }) => {
        const deleteRow = (row: number) => {
          setValues({ approver: values.approver.filter((_, i) => i !== row) }, true);
        };

        return (
          <Form noValidate className={css.form}>
            <div>
              {values.approver.map((_, i, arr) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i} className={css.formRow}>
                  <div className={css.input}>
                    <FormikInput
                      type="email"
                      name={`approver[${i}]`}
                      label="email"
                      hideLabel
                      placeholder="Please enter an email address"
                    />
                  </div>

                  <Button
                    data-id="delete-button"
                    display="square"
                    disabled={arr.length < 2}
                    onClick={() => {
                      deleteRow(i);
                    }}
                  >
                    <Icon InlineIcon={IconDelete} aria-hidden="true" />
                  </Button>

                  {i === arr.length - 1 && (
                    <Button
                      data-id="plus-button"
                      extraClasses={css.plusButton}
                      display="square"
                      onClick={() => {
                        setFieldValue(`approver[${i + 1}]`, "", false);
                      }}
                    >
                      <Icon InlineIcon={IconPlus} aria-hidden="true" />
                    </Button>
                  )}
                </div>
              ))}
            </div>
            <ButtonGroup>
              <Button extraClasses={css.formButton} type="reset">
                {formatMessage({ id: "general.cancel" })}
              </Button>
              <Button
                extraClasses={css.formButton}
                type="submit"
                appearance="primary"
                loading={submitLoading}
              >
                {formatMessage({ id: "general.save" })}
              </Button>
            </ButtonGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CountryApproverForm;
