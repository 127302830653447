import {
  IconBusTrainSolid,
  IconCalendarSolid,
  IconLockSolid,
  IconPersonBusinessSolid,
  IconPublicSolid,
  IconSettingsSolid,
  IconTicketShinySolid,
} from "@flixbus/honeycomb-icons-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { Formik } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import AccessPackageFilter from "../../formFields/accessPackageFilter/AccessPackageFilter";
import ApiPartnerFilter from "../../formFields/apiPartnerFilter/ApiPartnerFilter";
import AutoOnSaleConfigFilter from "../../formFields/autoOnSaleConfigFilter/AutoOnSaleConfigFilter";
import BusinessDeveloperFilter from "../../formFields/businessDeveloperFilter/BusinessDeveloperFilter";
import BusinessRegionChecklistFilter from "../../formFields/businessRegionChecklistFilter/BusinessRegionChecklistFilter";
import MeansOfTransportFilter from "../../formFields/meansOfTransportFilter/MeansOfTransportFilter";
import PartnerTagsFilter from "../../formFields/partnerTagsFilter/PartnerTagsFilter";
import {
  createInitialValues,
  createSubmittableValues,
} from "../../helper/partnerFilterValues/partnerFilterValues";
import { PartnerFilterFormSubmittableValues, SearchType } from "../../types";
import FilterForm from "../filterForm/FilterForm";

export const menu = [
  {
    label: <FormattedMessage id="general.bizRegion" />,
    icon: IconPublicSolid,
    content: <BusinessRegionChecklistFilter />,
  },
  {
    label: <FormattedMessage id="general.integrationType" />,
    icon: IconSettingsSolid,
    content: <ApiPartnerFilter />,
  },
  {
    label: <FormattedMessage id="lineForm.meansOfTransport.label" />,
    icon: IconBusTrainSolid,
    content: <MeansOfTransportFilter type={SearchType.PARTNERS} />,
  },
  {
    label: <FormattedMessage id="partnerForm.accessPackage.label" />,
    icon: IconLockSolid,
    content: <AccessPackageFilter />,
  },
  {
    label: <FormattedMessage id="general.marketplaceManager" />,
    icon: IconPersonBusinessSolid,
    content: <BusinessDeveloperFilter />,
  },
  {
    label: <FormattedMessage id="general.scheduleAutomation" />,
    icon: IconCalendarSolid,
    content: <AutoOnSaleConfigFilter />,
  },
];

export const partnerTagsMenuItem = {
  label: <FormattedMessage id="general.partnerTags.label" />,
  icon: IconTicketShinySolid,
  content: <PartnerTagsFilter />,
};

export type PartnerFilterFormProps = {
  defaultValues?: PartnerFilterFormSubmittableValues;
  onReset: () => void;
  onSubmit: (values: PartnerFilterFormSubmittableValues) => void;
};

const PartnerFilterForm: React.FC<PartnerFilterFormProps> = ({
  defaultValues,
  onReset,
  onSubmit,
}) => {
  if (
    hasUserPermission(Feature.VIEW_PARTNER_TAGS) &&
    !menu.includes(partnerTagsMenuItem)
  ) {
    menu.splice(3, 0, partnerTagsMenuItem);
  }

  return (
    <Formik
      initialValues={createInitialValues(defaultValues)}
      onSubmit={(values) => {
        onSubmit(createSubmittableValues(values));
      }}
      onReset={onReset}
      validateOnBlur={false}
      validate={async () => {
        return null;
      }}
    >
      <FilterForm menuItems={menu} />
    </Formik>
  );
};

export default PartnerFilterForm;
