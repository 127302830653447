import { add, isBefore, isSameDay } from "date-fns";
import { localizeDate } from "../../../../helper/localizeDate/localizeDate";
import formatMessage from "../../../translationProvider/helpers/formatMessage/formatMessage";

export const getDays = () => [
  { short: formatMessage("general.monday"), long: "Monday" },
  { short: formatMessage("general.tuesday"), long: "Tuesday" },
  { short: formatMessage("general.wednesday"), long: "Wednesday" },
  { short: formatMessage("general.thursday"), long: "Thursday" },
  { short: formatMessage("general.friday"), long: "Friday" },
  { short: formatMessage("general.saturday"), long: "Saturday" },
  { short: formatMessage("general.sunday"), long: "Sunday" },
];

export const getMonths = () => [
  formatMessage("general.january"),
  formatMessage("general.february"),
  formatMessage("general.march"),
  formatMessage("general.april"),
  formatMessage("general.may"),
  formatMessage("general.june"),
  formatMessage("general.july"),
  formatMessage("general.august"),
  formatMessage("general.september"),
  formatMessage("general.october"),
  formatMessage("general.november"),
  formatMessage("general.december"),
];

export const getStartDate = (earliestStart: Date, start?: Date, end?: Date): Date => {
  if (start && end === undefined) {
    return start;
  }
  return earliestStart;
};

// This function is handling the maximum selectable period
export const getEndDate = (latestEnd: Date, start?: Date, maxLength?: number): Date => {
  if (start && maxLength) {
    return add(start, { days: maxLength });
  }
  return latestEnd;
};

export const getSelectionAsString = (start?: Date, end?: Date): string => {
  if (start && end) {
    if (isBefore(end, start)) {
      return localizeDate(start);
    }
    if (isSameDay(start, end)) {
      return localizeDate(start);
    }
    return `${localizeDate(start)} - ${localizeDate(end)}`;
  }
  return "";
};
