import { Skeleton } from "@flixbus/honeycomb-react";
import { Autocomplete, ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import SelectApiError from "../../../../shared/components/selectApiError/SelectApiError";
import { createOptions } from "../../../../shared/helpers/autocompleteOptions/createOptions";
import filterOption from "../../../../shared/helpers/autocompleteOptions/filterOption";
import { BusinessRegion } from "../../../../shared/types/schema";
import { useFindAllBusinessRegionsQuery } from "../../api/operations.generated";

type Props = {
  readOnly?: boolean;
};

const BusinessRegionSelect: React.FC<Props> = ({ readOnly }) => {
  const [, meta, helper] = useField<BusinessRegion["id"]>("businessRegionId");
  const { formatMessage } = useIntl();

  const { loading, data, error } = useFindAllBusinessRegionsQuery({
    fetchPolicy: "cache-first",
  });

  if (loading) {
    return (
      <>
        <Skeleton height="sm" width="sm" />
        <Skeleton height="lg" flushBottom />
      </>
    );
  }

  if (data?.findAllBusinessRegions) {
    const options = createOptions(data.findAllBusinessRegions);

    if (readOnly) {
      return (
        <ReadOnlyFormField label={formatMessage({ id: "general.bizRegion" })}>
          {options.find((option) => option.value === meta.value)!.label}
        </ReadOnlyFormField>
      );
    }

    return (
      <Autocomplete
        label={`${formatMessage({
          id: "general.bizRegion",
        })} *`}
        options={options}
        placeholder={formatMessage({ id: "lineForm.businessRegion.select.placeholder" })}
        onValueSelected={(selectedValue) => helper.setValue(selectedValue.value)}
        error={!!(meta.error && meta.touched)}
        errorMessage={meta.error}
        startsOnInputValueLength={0}
        filterOption={filterOption}
        initialValue={options.find((option) => option.value === meta.value)}
        onBlur={() => helper.setTouched(true)}
      />
    );
  }

  if (error && !loading) {
    return (
      <SelectApiError
        label={formatMessage({ id: "general.bizRegion" })}
        info={formatMessage({ id: "error.errorOccured" })}
      />
    );
  }

  return <></>;
};

export default BusinessRegionSelect;
