import { Heading, Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikSwitch from "../../../../shared/components/formikSwitch/FormikSwitch";
import FormikTextarea from "../../../../shared/components/formikTextarea/FormikTextarea";
import InfoText from "../../../../shared/components/infoText/InfoText";
import { AutoOnSaleConfigInput } from "../../../../shared/types/schema";
import * as css from "./AutoOnSaleConfigurationInput.scss";

type Props = {
  readOnly?: boolean;
};

const AutoOnSaleConfigurationInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<AutoOnSaleConfigInput>("autoOnSaleConfig");

  return (
    <>
      <CustomFormRow>
        <Heading size={4} extraClasses={css.heading}>
          <FormattedMessage id="partnerForm.autoOnSaleConfig.headline" />
        </Heading>
        <InfoText text={formatMessage({ id: "partnerForm.autoOnSaleConfig.info" })} />
      </CustomFormRow>
      <CustomFormRow>
        <FormikSwitch
          label={formatMessage({ id: "partnerForm.autoOnSaleConfig.normalSchedule" })}
          name="autoOnSaleConfig.normalSchedule"
          readOnly={readOnly}
          small
        />
      </CustomFormRow>
      <CustomFormRow>
        <FormikSwitch
          label={formatMessage({
            id: "partnerForm.autoOnSaleConfig.conceptChangeSchedule",
          })}
          name="autoOnSaleConfig.conceptChangeSchedule"
          readOnly={readOnly}
          small
        />
      </CustomFormRow>
      {(!meta.value.normalSchedule || !meta.value.conceptChangeSchedule) && (
        <CustomFormRow>
          <Grid>
            <GridCol size={6}>
              <FormikTextarea
                label={formatMessage({ id: "partnerForm.autoOnSaleConfig.reason" })}
                name="autoOnSaleConfig.reason"
                placeholder={formatMessage({
                  id: "partnerForm.autoOnSaleConfig.reason.placeholder",
                })}
                readOnly={readOnly}
              />
            </GridCol>
          </Grid>
        </CustomFormRow>
      )}
    </>
  );
};

export default AutoOnSaleConfigurationInput;
