// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// This file was automatically generated using npm run codegen
// If you want to alter this file please do so via the above mentioned command
// +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
import * as Types from "../../../shared/types/schema";

import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
const defaultOptions = {} as const;
export type ScheduleDetailFragment = {
  __typename?: "Schedule";
  id: string;
  name: string;
  status: Types.ScheduleStatus;
  displayedScheduleStatus: Types.DisplayedScheduleStatus;
  isOutdated: boolean;
  readOnly: boolean;
  period: { __typename?: "SchedulePeriod"; start: any; end: any };
  nonOperatingPeriods: Array<{ __typename?: "NonOperatingPeriod"; start: any; end: any }>;
  circulation?: { __typename?: "Circulation"; uuid: string } | null;
  onSalePeriods: Array<{ __typename?: "Period"; start: any; end: any }>;
  originalSchedule: { __typename?: "Schedule"; id: string; status: Types.ScheduleStatus };
  line: {
    __typename?: "Line";
    id: string;
    partner: { __typename?: "Partner"; id: string };
  };
  conceptChangeOf?: { __typename?: "Schedule"; id: string; name: string } | null;
  conceptChanges: Array<{
    __typename?: "Schedule";
    id: string;
    name: string;
    period: { __typename?: "SchedulePeriod"; start: any; end: any };
  }>;
};

export type ScheduleDetailQueryVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
}>;

export type ScheduleDetailQuery = {
  __typename?: "Query";
  findSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    status: Types.ScheduleStatus;
    displayedScheduleStatus: Types.DisplayedScheduleStatus;
    isOutdated: boolean;
    readOnly: boolean;
    period: { __typename?: "SchedulePeriod"; start: any; end: any };
    nonOperatingPeriods: Array<{
      __typename?: "NonOperatingPeriod";
      start: any;
      end: any;
    }>;
    circulation?: { __typename?: "Circulation"; uuid: string } | null;
    onSalePeriods: Array<{ __typename?: "Period"; start: any; end: any }>;
    originalSchedule: {
      __typename?: "Schedule";
      id: string;
      status: Types.ScheduleStatus;
    };
    line: {
      __typename?: "Line";
      id: string;
      partner: { __typename?: "Partner"; id: string };
    };
    conceptChangeOf?: { __typename?: "Schedule"; id: string; name: string } | null;
    conceptChanges: Array<{
      __typename?: "Schedule";
      id: string;
      name: string;
      period: { __typename?: "SchedulePeriod"; start: any; end: any };
    }>;
  };
};

export type UpdateScheduleMutationVariables = Types.Exact<{
  id: Types.Scalars["ID"]["input"];
  name?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  period?: Types.InputMaybe<Types.SchedulePeriodInput>;
  circulationId?: Types.InputMaybe<Types.Scalars["String"]["input"]>;
  nonOperatingPeriods?: Types.InputMaybe<
    Array<Types.NonOperatingPeriodInput> | Types.NonOperatingPeriodInput
  >;
}>;

export type UpdateScheduleMutation = {
  __typename?: "Mutation";
  updateSchedule: {
    __typename?: "Schedule";
    id: string;
    name: string;
    status: Types.ScheduleStatus;
    displayedScheduleStatus: Types.DisplayedScheduleStatus;
    isOutdated: boolean;
    readOnly: boolean;
    period: { __typename?: "SchedulePeriod"; start: any; end: any };
    nonOperatingPeriods: Array<{
      __typename?: "NonOperatingPeriod";
      start: any;
      end: any;
    }>;
    circulation?: { __typename?: "Circulation"; uuid: string } | null;
    onSalePeriods: Array<{ __typename?: "Period"; start: any; end: any }>;
    originalSchedule: {
      __typename?: "Schedule";
      id: string;
      status: Types.ScheduleStatus;
    };
    line: {
      __typename?: "Line";
      id: string;
      partner: { __typename?: "Partner"; id: string };
    };
    conceptChangeOf?: { __typename?: "Schedule"; id: string; name: string } | null;
    conceptChanges: Array<{
      __typename?: "Schedule";
      id: string;
      name: string;
      period: { __typename?: "SchedulePeriod"; start: any; end: any };
    }>;
  };
};

export const ScheduleDetailFragmentDoc = gql`
  fragment ScheduleDetail on Schedule {
    id
    name
    status
    displayedScheduleStatus
    isOutdated
    readOnly
    period {
      start
      end
    }
    nonOperatingPeriods {
      start
      end
    }
    circulation {
      uuid
    }
    onSalePeriods {
      start
      end
    }
    originalSchedule {
      id
      status
    }
    line {
      id
      partner {
        id
      }
    }
    conceptChangeOf {
      id
      name
    }
    conceptChanges {
      id
      name
      period {
        start
        end
      }
    }
  }
`;
export const ScheduleDetailDocument = gql`
  query ScheduleDetail($id: ID!) {
    findSchedule(id: $id) {
      ...ScheduleDetail
    }
  }
  ${ScheduleDetailFragmentDoc}
`;

/**
 * __useScheduleDetailQuery__
 *
 * To run a query within a React component, call `useScheduleDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useScheduleDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScheduleDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useScheduleDetailQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  > &
    ({ variables: ScheduleDetailQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<ScheduleDetailQuery, ScheduleDetailQueryVariables>(
    ScheduleDetailDocument,
    options
  );
}
export function useScheduleDetailLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<ScheduleDetailQuery, ScheduleDetailQueryVariables>(
    ScheduleDetailDocument,
    options
  );
}
export function useScheduleDetailSuspenseQuery(
  baseOptions?:
    | ApolloReactHooks.SkipToken
    | ApolloReactHooks.SuspenseQueryHookOptions<
        ScheduleDetailQuery,
        ScheduleDetailQueryVariables
      >
) {
  const options =
    baseOptions === ApolloReactHooks.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useSuspenseQuery<
    ScheduleDetailQuery,
    ScheduleDetailQueryVariables
  >(ScheduleDetailDocument, options);
}
export type ScheduleDetailQueryHookResult = ReturnType<typeof useScheduleDetailQuery>;
export type ScheduleDetailLazyQueryHookResult = ReturnType<
  typeof useScheduleDetailLazyQuery
>;
export type ScheduleDetailSuspenseQueryHookResult = ReturnType<
  typeof useScheduleDetailSuspenseQuery
>;
export const UpdateScheduleDocument = gql`
  mutation UpdateSchedule(
    $id: ID!
    $name: String
    $period: SchedulePeriodInput
    $circulationId: String
    $nonOperatingPeriods: [NonOperatingPeriodInput!]
  ) {
    updateSchedule(
      id: $id
      name: $name
      period: $period
      circulationId: $circulationId
      nonOperatingPeriods: $nonOperatingPeriods
    ) {
      ...ScheduleDetail
    }
  }
  ${ScheduleDetailFragmentDoc}
`;

/**
 * __useUpdateScheduleMutation__
 *
 * To run a mutation, you first call `useUpdateScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduleMutation, { data, loading, error }] = useUpdateScheduleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      period: // value for 'period'
 *      circulationId: // value for 'circulationId'
 *      nonOperatingPeriods: // value for 'nonOperatingPeriods'
 *   },
 * });
 */
export function useUpdateScheduleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateScheduleMutation,
    UpdateScheduleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateScheduleMutation,
    UpdateScheduleMutationVariables
  >(UpdateScheduleDocument, options);
}
export type UpdateScheduleMutationHookResult = ReturnType<
  typeof useUpdateScheduleMutation
>;
