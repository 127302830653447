import { SelectGroup } from "@flixbus/honeycomb-react";
import { ReadOnlyFormField } from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import getWeekdayText from "../../../../shared/helpers/getWeekdayText/getWeekdayText";
import { DayOfWeek } from "../../../../shared/types/schema";
import getReadonlyDays from "../../helpers/getReadonlyDays/getReadonlyDays";
import weekdaysMap from "../../helpers/weekdaysMap/weekdaysMap";

type Props = {
  label: string;
  name: string;
  readOnly?: boolean;
  selectIdentificator?: string;
};

const FormikDaySelect: React.FC<Props> = ({
  label,
  name,
  readOnly,
  selectIdentificator,
}) => {
  const [, meta, helper] = useField<Array<DayOfWeek | undefined>>(name);

  const error = meta.error || undefined;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDays = [...meta.value];
    const selectedDay = event.target.value as DayOfWeek;
    const selectedDayIndex = weekdaysMap[selectedDay];
    const prevState = updatedDays[selectedDayIndex];

    if (prevState) {
      updatedDays[selectedDayIndex] = undefined;
    } else {
      updatedDays[selectedDayIndex] = selectedDay;
    }

    return helper.setValue(updatedDays);
  };

  const options = Object.values(DayOfWeek).map((day, i) => {
    return {
      id:
        selectIdentificator !== undefined
          ? `option-${selectIdentificator}-${i}`
          : `option-${i}`,
      value: day,
      name: "weekday-select-option",
      label: getWeekdayText(day),
      defaultChecked: !!meta.value[i],
      onChange,
    };
  });

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>{getReadonlyDays(meta.value)}</ReadOnlyFormField>
    );
  }

  return (
    <SelectGroup
      id={
        selectIdentificator
          ? `weekday-select-group-${selectIdentificator}`
          : "weekday-select-group"
      }
      aria-label="Select day of week"
      label={label}
      multi
      options={options}
      error={!!error}
      infoError={error}
    />
  );
};

export default FormikDaySelect;
