import { Grid, GridCol } from "@flixbus/honeycomb-react";
import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import CustomFormRow from "../../../../shared/components/customFormRow/CustomFormRow";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import FormikMultiSelect from "../../../../shared/components/formikMultiSelect/FormikMultiSelect";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import { createTranslatedOptionsFromEnum } from "../../../../shared/helpers/autocompleteOptions/createOptions";
import { IsoLanguage, PurposeOfContactField } from "../../../../shared/types/schema";
import emptyCustomerServiceContactFormRow from "../../helpers/emptyCustomerServiceContactFormRow/emptyCustomerServiceContactFormRow";
import { CustomerServiceContactFormValue } from "../../types";
import CustomerServiceWorkingHoursRow from "../customerServiceWorkingHoursRow/CustomerServiceWorkingHoursRow";
import HolidayWorkingHoursInput from "../holidayWorkingHoursInput/HolidayWorkingHoursInput";
import RowButtons from "../rowButtons/RowButtons";
import * as css from "./CustomerServiceContactFormRow.scss";

type Props = {
  indexOfContact: number;
  name: string;
  readOnly?: boolean;
};

const CustomerServiceContactFormRow: React.FC<Props> = ({
  indexOfContact,
  name,
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const [, meta, helpers] = useField<CustomerServiceContactFormValue>(name);

  return (
    <>
      <CustomFormRow>
        <Grid>
          <GridCol size={5}>
            <FormikSelect
              label={formatMessage({ id: "purposeOfContact.select.label" })}
              placeholder={formatMessage({ id: "purposeOfContact.select.placeholder" })}
              name={`${name}.purposeOfContact`}
              options={createTranslatedOptionsFromEnum(
                PurposeOfContactField,
                "purposeOfContact"
              )}
              readOnly={readOnly}
            />
          </GridCol>
          <GridCol size={5}>
            <FormikMultiSelect
              label={formatMessage({ id: "languagesSpoken.select.label" })}
              placeholder={formatMessage({ id: "languagesSpoken.select.placeholder" })}
              name={`${name}.languagesSpoken`}
              options={createTranslatedOptionsFromEnum(IsoLanguage, "language")}
              readOnly={readOnly}
              maxToShow={2}
            />
          </GridCol>
        </Grid>
      </CustomFormRow>
      <CustomFormRow>
        <Grid>
          <GridCol size={5}>
            <FormikInput
              label={formatMessage({ id: "hotlineNumber.input.label" })}
              type="tel"
              name={`${name}.hotlineNumber`}
              readOnly={readOnly}
              placeholder="+49 89 12345"
              readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
            />
          </GridCol>

          <GridCol size={5}>
            <FormikInput
              label={formatMessage({ id: "general.email" })}
              type={readOnly ? "text" : "email"}
              name={`${name}.email`}
              readOnly={readOnly}
              readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
            />
          </GridCol>
        </Grid>
      </CustomFormRow>

      {meta.value.workingHours?.map((_, i, arr) => (
        // Rerendering when the index changes is exactly what we want here, thats why we
        // eslint-disable-next-line react/no-array-index-key
        <CustomFormRow key={i}>
          <div
            data-id="customer-service-working-hours-row"
            className={css.workingHoursWrapper}
          >
            <CustomerServiceWorkingHoursRow
              indexOfContact={indexOfContact}
              indexOfWorkingHour={i}
              name={name}
              readOnly={readOnly}
            />

            {!readOnly && (
              <RowButtons
                onAdd={() => {
                  helpers.setValue({
                    ...meta.value,
                    workingHours: [
                      ...meta.value.workingHours,
                      emptyCustomerServiceContactFormRow.workingHours[0],
                    ],
                  });
                }}
                onDelete={() => {
                  helpers.setValue({
                    ...meta.value,
                    workingHours: [
                      ...meta.value.workingHours.slice(0, i),
                      ...meta.value.workingHours.slice(i + 1, arr.length),
                    ],
                  });
                }}
                indexOfRow={i}
                totalNumOfRows={arr.length}
              />
            )}
          </div>
        </CustomFormRow>
      ))}

      <CustomFormRow>
        <HolidayWorkingHoursInput
          name={`${name}.holidayWorkingHours`}
          readOnly={readOnly}
        />
      </CustomFormRow>
    </>
  );
};

export default CustomerServiceContactFormRow;
