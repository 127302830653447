import { DefaultInfoAlert } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import Layout from "../../ui/Layout";
import ScheduleHeader from "../scheduleHeader/ScheduleHeader";
import ScheduleHeaderWrapper from "../scheduleHeader/ui/scheduleHeaderWrapper/ScheduleHeaderWrapper";
import { useScheduleDetailQuery } from "./api/operations.generated";
import ScheduleInfo from "./containers/scheduleInfo/ScheduleInfo";
import ConceptChangeInfoBox from "./ui/conceptChangeInfoBox/ConceptChangeInfoBox";
import ScheduleDetailSkeleton from "./ui/scheduleDetailSkeleton/ScheduleDetailSkeleton";

type Props = {
  scheduleId: string;
};

const ScheduleDetail: React.FC<Props> = ({ scheduleId }) => {
  const { data, loading, error } = useScheduleDetailQuery({
    variables: {
      id: scheduleId,
    },
  });

  if (loading) {
    return <ScheduleDetailSkeleton scheduleId={scheduleId} />;
  }

  if (error) {
    return (
      <ScheduleHeaderWrapper scheduleId={scheduleId}>
        <DefaultInfoAlert message={error.message} />
      </ScheduleHeaderWrapper>
    );
  }

  if (data?.findSchedule) {
    const schedule = data.findSchedule;

    return (
      <>
        <ScheduleHeader schedule={schedule} />
        <Layout noTopPadding>
          <ScheduleInfo schedule={schedule} />
          <ConceptChangeInfoBox
            conceptChangeOf={schedule.conceptChangeOf}
            conceptChanges={schedule.conceptChanges}
          />
        </Layout>
      </>
    );
  }

  return <></>;
};

export default ScheduleDetail;
