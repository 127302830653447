import { Switch } from "@flixbus/honeycomb-react";
import { useFormikContext } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import { PolicyType } from "../../../../shared/types/schema";
import { PoliciesFormValues } from "../../types";

export type PoliciesDifferentiationSwitchProps = {
  readOnly?: boolean;
};

const PoliciesDifferentiationSwitch: React.FC<PoliciesDifferentiationSwitchProps> = ({
  readOnly,
}) => {
  const { formatMessage } = useIntl();
  const { values, setValues } = useFormikContext<PoliciesFormValues>();

  const isChecked = values.policies.length === 2;

  return (
    <Switch
      id="policy-switch"
      label={formatMessage({ id: "policies.switch.label" })}
      checked={isChecked}
      onChange={() => {
        if (isChecked) {
          setValues(
            {
              ...values,
              policies: [
                {
                  type: PolicyType.Both,
                  policy: values.policies[0].policy,
                  additionalInformation: values.policies[0].additionalInformation,
                },
              ],
            },
            false
          );
        } else {
          setValues({
            ...values,
            policies: [
              {
                type: PolicyType.Domestic,
                policy: values.policies[0].policy,
                additionalInformation: values.policies[0].additionalInformation,
              },
              {
                type: PolicyType.International,
                policy: values.policies[0].policy,
                additionalInformation: values.policies[0].additionalInformation,
              },
            ],
          });
        }
      }}
      small
      disabled={readOnly}
    />
  );
};

export default PoliciesDifferentiationSwitch;
