import * as React from "react";
import { useParams } from "react-router-dom";
import ScheduleDetail from "../../schedule/scheduleDetail/ScheduleDetail";
import ScheduleTab from "../../schedule/scheduleTab/ScheduleTab";
import TimeFormatPopup from "../../shared/components/timeFormatPopup/TimeFormatPopup";

const ShowScheduleDetail: React.FC = () => {
  const { scheduleId } = useParams();
  return (
    <>
      <ScheduleDetail scheduleId={scheduleId!} />
      <ScheduleTab scheduleId={scheduleId!} />
      <TimeFormatPopup />
    </>
  );
};

export default ShowScheduleDetail;
