import { Panel, PanelContent, PanelHeader } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import FormikButtons from "../../../../shared/components/formikButtons/FormikButtons";
import getEditableScheduleFields from "../../../../shared/helpers/getEditableFormFields/getEditableScheduleFields/getEditableScheduleFields";
import ScheduleForm from "../../../scheduleForm/ScheduleForm";
import { ScheduleSubmittableFields } from "../../../scheduleForm/types";
import { ScheduleDetailQuery } from "../../api/operations.generated";
import * as css from "./ScheduleDetailPanel.scss";

export type ScheduleDetailPanelProps = {
  schedule: ScheduleDetailQuery["findSchedule"];
  active: boolean;
  submitLoading: boolean;
  onSubmit: (values: ScheduleSubmittableFields) => void;
  onCancel: () => void;
};

const ScheduleDetailPanel: React.FC<ScheduleDetailPanelProps> = ({
  schedule,
  active,
  submitLoading,
  onSubmit,
  onCancel,
}) => {
  const { formatMessage } = useIntl();

  return (
    <Panel id="food-panel" active={active} hasOverlay onOverlayClick={onCancel}>
      <PanelHeader
        backButtonProps={{ label: "Back", onClick: onCancel }}
        closeButtonProps={{ label: "Close", onClick: onCancel }}
      >
        <FormattedMessage id="scheduleDetail.header" />
      </PanelHeader>
      <PanelContent>
        <ScheduleForm
          partnerId={schedule.line.partner.id}
          defaultValues={{
            name: schedule.name,
            period: {
              start: new Date(schedule.period.start),
              end: new Date(schedule.period.end),
            },
            nonOperatingPeriods: schedule.nonOperatingPeriods.map((period) => ({
              start: new Date(period.start),
              end: new Date(period.end),
            })),
            circulationId: schedule.circulation?.uuid,
          }}
          onSubmit={onSubmit}
          onCancel={onCancel}
          editableFields={getEditableScheduleFields(false, schedule.readOnly)}
        >
          <div className={css.formButtonsWrapper}>
            <div className={css.formButtons}>
              <FormikButtons
                submitButtonText={formatMessage({ id: "general.save" })}
                submitButtonAppearance="primary"
                submitButtonLoading={submitLoading}
                resetButtonText={formatMessage({ id: "general.cancel" })}
                disableSubmitWhenNotDirty
              />
            </div>
          </div>
        </ScheduleForm>
      </PanelContent>
    </Panel>
  );
};

export default ScheduleDetailPanel;
