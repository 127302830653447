import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import createCirculationOption from "../../helpers/reactSelect/createCirculationOption/createCirculationOption";
import { Circulation, Partner } from "../../types/schema";
import FormikSelect from "../formikSelect/FormikSelect";
import InfoTooltipLabel from "../infoTooltipLabel/InfoTooltipLabel";
import InputSkeleton from "../inputSkeleton/InputSkeleton";
import SelectApiError from "../selectApiError/SelectApiError";
import { useFindPartnerDetailsForCirculationSelectQuery } from "./api/operations.generated";

export type CirculationSelectProps = {
  name: string;
  label: string;
  partnerId: Partner["id"];
  selectedCirculationId?: string;
  extraClasses?: string;
  readOnly?: boolean;
  isClearable?: boolean;
};

const CirculationSelect: React.FC<CirculationSelectProps> = ({
  name,
  label,
  partnerId,
  selectedCirculationId,
  extraClasses,
  readOnly,
  isClearable = true,
}) => {
  const { formatMessage } = useIntl();
  const [, , helper] = useField<Circulation["uuid"]>(name);

  const {
    data: queryData,
    loading: queryLoading,
    error: queryError,
  } = useFindPartnerDetailsForCirculationSelectQuery({
    variables: {
      id: partnerId,
    },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (
        selectedCirculationId &&
        data.findPartner.circulations.find(
          (circulation) => circulation.uuid === selectedCirculationId
        )
      ) {
        helper.setValue(selectedCirculationId);
      }
    },
  });

  if (queryLoading) {
    return <InputSkeleton />;
  }

  if (queryError) {
    return (
      <SelectApiError label={label} info={formatMessage({ id: "error.errorOccured" })} />
    );
  }

  if (queryData?.findPartner) {
    return (
      <div className={extraClasses}>
        <InfoTooltipLabel
          labelText={label}
          tooltipContent={formatMessage({ id: "circulations.info.text" })}
        />
        <FormikSelect
          name={name}
          placeholder={formatMessage({ id: "partnerDetail.defaultCapacity.placeholder" })}
          options={
            queryData?.findPartner?.circulations
              ? queryData.findPartner.circulations.map((circulation) =>
                  createCirculationOption(circulation)
                )
              : []
          }
          isClearable={isClearable}
          readOnly={readOnly}
        />
      </div>
    );
  }

  return <></>;
};

export default CirculationSelect;
