import { getPeriodAsString } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { ScheduleDetailQuery } from "../../api/operations.generated";
import * as css from "./OnSalePeriodsText.scss";

type Props = {
  onSalePeriods: ScheduleDetailQuery["findSchedule"]["onSalePeriods"];
};

const OnSalePeriodsText: React.FC<Props> = ({ onSalePeriods }) => {
  const getOnSalePeriodText = (
    onSalePeriod: ScheduleDetailQuery["findSchedule"]["onSalePeriods"][number]
  ) => getPeriodAsString(new Date(onSalePeriod.start), new Date(onSalePeriod.end));

  let onSalePeriodsText = <></>;
  switch (onSalePeriods.length) {
    case 0:
      onSalePeriodsText = <></>;
      break;
    case 1:
      onSalePeriodsText = <>{getOnSalePeriodText(onSalePeriods[0])}</>;
      break;
    case 2:
      onSalePeriodsText = (
        <>
          {getOnSalePeriodText(onSalePeriods[0])}
          &nbsp;&bull;&nbsp;
          {getOnSalePeriodText(onSalePeriods[1])}
        </>
      );
      break;
    default:
      onSalePeriodsText = (
        <>
          {getOnSalePeriodText(onSalePeriods[0])}
          &nbsp;&bull;&nbsp;
          {getOnSalePeriodText(onSalePeriods[1])}
          ... +{onSalePeriods.length - 2}
        </>
      );
  }

  return <div className={css.onSalePeriods}>{onSalePeriodsText}</div>;
};

export default OnSalePeriodsText;
