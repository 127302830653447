import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikCheckbox from "../../../../shared/components/formikCheckbox/FormikCheckbox";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import FormikSelectGroup from "../../../../shared/components/formikSelectGroup/FormikSelectGroup";
import getCurrenciesAsSelectOptions from "../../../../shared/helpers/getCurrenciesAsSelectOptions/getCurrenciesAsSelectOptions";
import { CheckInDeskFeeUnit } from "../../../../shared/types/schema";
import { PartnerCustomerServiceAndPoliciesFormValues } from "../../types";
import * as css from "./CheckInDeskInput.scss";

type Props = {
  readOnly?: boolean;
};

const CheckInDeskInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta] = useField<
    PartnerCustomerServiceAndPoliciesFormValues["checkInRequirements"]["checkInOptions"]["desk"]
  >("checkInRequirements.checkInOptions.desk");

  return (
    <>
      <FormikCheckbox
        name="checkInRequirements.checkInOptions.desk.enabled"
        label={formatMessage({
          id: "partnerForm.checkInRequirements.checkInOptions.option.desk",
        })}
        readOnly={readOnly}
      />

      {meta.value.enabled && (
        <div className={css.wrapper}>
          <div className={css.feePriceWrapper}>
            <div className={css.selectWrapper}>
              <FormikSelect
                name="checkInRequirements.checkInOptions.desk.currency"
                label={formatMessage({ id: "prices.currency.select.label" })}
                options={getCurrenciesAsSelectOptions()}
                readOnly={readOnly}
              />
            </div>

            <div className={css.inputWrapper}>
              <FormikInput
                name="checkInRequirements.checkInOptions.desk.fee"
                label={formatMessage({ id: "policies.fee.label" })}
                placeholder="0,00"
                type="text"
                isPriceInput
                readOnly={readOnly}
              />
            </div>
          </div>

          <FormikSelectGroup
            name="checkInRequirements.checkInOptions.desk.feeUnit"
            label={formatMessage({ id: "policies.feeType.label" })}
            defaultOption={CheckInDeskFeeUnit.PerBooking}
            defaultOptionLabel={formatMessage({
              id: "partnerForm.checkInRequirements.checkInOptions.desk.option.feeUnit.PER_BOOKING",
            })}
            alternativeOption={CheckInDeskFeeUnit.PerPerson}
            alternativeOptionLabel={formatMessage({
              id: "partnerForm.checkInRequirements.checkInOptions.desk.option.feeUnit.PER_PERSON",
            })}
            defaultIsPreselected={false}
            readOnly={readOnly}
          />
        </div>
      )}
    </>
  );
};

export default CheckInDeskInput;
