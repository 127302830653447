import { Heading } from "@flixbus/honeycomb-react";
import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { Form, Formik } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import { PartnerEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import * as css from "../../shared/styles/heading.scss";
import { InterconnectionRuleType, InventoryRestriction } from "../../shared/types/schema";
import getSubmittableValues from "./helpers/getSubmittableValues/getSubmittableValues";
import validationSchema from "./helpers/validationSchema/validationSchema";
import {
  PartnerSettingsDefaultValues,
  PartnerSettingsFormValues,
  PartnerSettingsSubmittableValues,
} from "./types";
import ApiSettingsInput from "./ui/apiSettingsInput/ApiSettingsInput";
import AutoOnSaleConfigurationInput from "./ui/autoOnSaleConfigurationInput/AutoOnSaleConfigurationInput";
import InterconnectionPricingRulesInput from "./ui/interconnectionPricingRulesInput/InterconnectionPricingRulesInput";
import InterconnectionRulesInput from "./ui/interconnectionRulesInput/InterconnectionRulesInput";

type Props = {
  onSubmit: (values: PartnerSettingsSubmittableValues) => void;
  onCancel: () => void;
  defaultValues?: PartnerSettingsDefaultValues;
  hasMobilityPartner?: boolean;
  isCreation?: boolean;
  editableFields?: PartnerEditableFields["settingsInput"];
};

const PartnerSettingsForm: React.FC<Props> = ({
  onSubmit,
  onCancel,
  defaultValues,
  hasMobilityPartner,
  isCreation = true,
  children,
  editableFields,
}) => {
  const initialValues: PartnerSettingsFormValues = {
    totalInterconnectionPricing: defaultValues
      ? defaultValues.totalInterconnectionPricing
      : true,
    priceChangeAllowed: defaultValues?.priceChangeAllowed || false,
    externalInterconnectionPricingAllowed:
      defaultValues?.externalInterconnectionPricingAllowed || false,
    apiSettings: {
      apiPartner: defaultValues?.apiSettings?.apiPartner || false,
      apiDisabledFeatures: {
        manageCapacity: defaultValues?.apiSettings
          ? defaultValues.apiSettings.apiDisabledFeatures.manageCapacity
          : true,
      },
      ticketSendout: {
        enabled: defaultValues?.apiSettings?.ticketSendout.enabled || false,
        checkInInformation: defaultValues?.apiSettings?.ticketSendout.checkInInformation
          .length
          ? defaultValues.apiSettings.ticketSendout.checkInInformation
          : [],
      },
    },
    autoOnSaleConfig: {
      normalSchedule: defaultValues?.autoOnSaleConfig.normalSchedule || false,
      conceptChangeSchedule:
        defaultValues?.autoOnSaleConfig.conceptChangeSchedule || false,
      reason: defaultValues?.autoOnSaleConfig.reason || "",
    },
    inventoryRule: {
      inventoryRestriction:
        defaultValues?.inventoryRule?.inventoryRestriction || InventoryRestriction.None,
      interconnectionRule: {
        type:
          defaultValues?.inventoryRule?.interconnectionRule?.type ||
          InterconnectionRuleType.None,
        operators: defaultValues?.inventoryRule?.interconnectionRule?.operators || [],
      },
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        onSubmit(getSubmittableValues(values, isCreation));
      }}
      onReset={onCancel}
    >
      <Form noValidate>
        <div data-id="api-settings-section">
          <Heading size={4} Elem="h6" extraClasses={css.heading}>
            <FormattedMessage id="partnerForm.apiSettings.headline" />
          </Heading>

          <CustomFormRow>
            <ApiSettingsInput
              readOnly={!editableFields?.allOtherSettings}
              apiPartnerReadOnly={!editableFields?.isApiPartner}
              isCreation={isCreation}
            />
          </CustomFormRow>
        </div>
        <AutoOnSaleConfigurationInput />
        {hasUserPermission(Feature.VIEW_PARTNER_IC_PRICING_RULES) && (
          <InterconnectionPricingRulesInput readOnly={!editableFields?.icPricingRules} />
        )}

        <InterconnectionRulesInput hasMobilityPartner={hasMobilityPartner} />

        {children}
      </Form>
    </Formik>
  );
};

export default PartnerSettingsForm;
