import { Button, ButtonGroup } from "@flixbus/honeycomb-react";
import { Form } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import NavSideForm, {
  NavSideFormItems,
} from "../../../shared/components/navSideForm/NavSideForm";
import * as css from "./FilterForm.scss";

export type FilterFormProps = {
  menuItems: NavSideFormItems;
};

const FilterForm: React.FC<FilterFormProps> = ({ menuItems }) => {
  return (
    <Form noValidate>
      <div>
        <NavSideForm items={menuItems} />

        <div className={css.buttonWrapper}>
          <ButtonGroup>
            <Button appearance="link" type="reset">
              <FormattedMessage id="general.cancel" />
            </Button>
            <Button appearance="primary" type="submit">
              <FormattedMessage id="general.confirm" />
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </Form>
  );
};

export default FilterForm;
