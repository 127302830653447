import { Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import CustomFormRow from "../../shared/components/customFormRow/CustomFormRow";
import FormikAccordion from "../../shared/components/formikAccordion/FormikAccordion";
import FormikCheckbox from "../../shared/components/formikCheckbox/FormikCheckbox";
import FormikInput from "../../shared/components/formikInput/FormikInput";
import FormikTimeUnitInput from "../../shared/components/formikTimeUnitInput/FormikTimeUnitInput";
import { PartnerEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { CustomerServiceAndPoliciesInput, TimeUnit } from "../../shared/types/schema";
import * as css from "./PartnerCustomerServiceAndPoliciesForm.scss";
import getInitialValues from "./helpers/getInitialValues/getInitialValues";
import getSubmittableValues from "./helpers/getSubmittableValues/getSubmittableValues";
import validationSchema from "./helpers/validationSchema/validationSchema";
import {
  PartnerCustomerServiceAndPoliciesDefaultValues,
  PartnerCustomerServiceAndPoliciesFormValues,
} from "./types";
import CheckInDeskInput from "./ui/checkInDeskInput/CheckInDeskInput";
import CheckInWebsiteInput from "./ui/checkInWebsiteInput/CheckInWebsiteInput";
import CustomerServiceContactForm from "./ui/customerServiceContactForm/CustomerServiceContactForm";
import IdentityProofInput from "./ui/identityProofInput/IdentityProofInput";

export type PartnerCustomerServiceAndPoliciesFormProps = {
  onSubmit: (values: CustomerServiceAndPoliciesInput) => void;
  onCancel: () => void;
  defaultValues?: PartnerCustomerServiceAndPoliciesDefaultValues;
  editableFields?: PartnerEditableFields["customerServiceAndPoliciesInput"];
};

const PartnerCustomerServiceAndPoliciesForm: React.FC<
  PartnerCustomerServiceAndPoliciesFormProps
> = ({ onSubmit, onCancel, defaultValues, editableFields, children }) => {
  const { formatMessage } = useIntl();
  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={getInitialValues(defaultValues)}
      onSubmit={(values) => {
        const submittableValues = getSubmittableValues(values);
        onSubmit(submittableValues);
      }}
      onReset={onCancel}
      validateOnChange
    >
      <Form noValidate>
        <FormikAccordion<PartnerCustomerServiceAndPoliciesFormValues>
          title={formatMessage({ id: "partnerForm.checkInRequirements.headline" })}
          name="checkInRequirements"
          openInitially={!defaultValues}
        >
          <div>
            <Heading size={4} extraClasses={css.heading}>
              <FormattedMessage id="partnerForm.checkInRequirements.checkInTime.heading" />
            </Heading>
            <CustomFormRow>
              <Grid>
                <GridCol size={4}>
                  <FormikTimeUnitInput
                    name="checkInRequirements.checkInTimeStart"
                    label={formatMessage({
                      id: "partnerForm.checkInRequirements.checkInStart.label",
                    })}
                    defaultUnit={TimeUnit.Minutes}
                    optionalUnit={TimeUnit.Hours}
                    info={formatMessage({
                      id: "partnerForm.checkInRequirements.checkInTime.info",
                    })}
                    readOnly={!editableFields?.checkInRequirements}
                  />
                </GridCol>
                <GridCol size={4}>
                  <FormikTimeUnitInput
                    name="checkInRequirements.checkInTimeEnd"
                    label={formatMessage({
                      id: "partnerForm.checkInRequirements.checkInEnd.label",
                    })}
                    defaultUnit={TimeUnit.Minutes}
                    optionalUnit={TimeUnit.Hours}
                    info={formatMessage({
                      id: "partnerForm.checkInRequirements.checkInTime.info",
                    })}
                    readOnly={!editableFields?.checkInRequirements}
                  />
                </GridCol>
              </Grid>
            </CustomFormRow>
          </div>
          <div>
            <Grid>
              <GridCol size={6}>
                <Heading size={4} extraClasses={css.heading}>
                  <FormattedMessage id="partnerForm.checkInRequirements.checkInOptions.heading" />
                </Heading>
                <CustomFormRow small>
                  <CheckInWebsiteInput readOnly={!editableFields?.checkInRequirements} />
                </CustomFormRow>
                <CustomFormRow small>
                  <FormikCheckbox
                    label={formatMessage({
                      id: "partnerForm.checkInRequirements.checkInOptions.option.shop",
                    })}
                    name="checkInRequirements.checkInOptions.shop.enabled"
                    readOnly={!editableFields?.checkInRequirements}
                  />
                </CustomFormRow>
                <CustomFormRow small>
                  <CheckInDeskInput readOnly={!editableFields?.checkInRequirements} />
                </CustomFormRow>
                <CustomFormRow>
                  <FormikCheckbox
                    label={formatMessage({
                      id: "partnerForm.checkInRequirements.checkInOptions.option.selfService",
                    })}
                    name="checkInRequirements.checkInOptions.selfServiceMachine.enabled"
                    readOnly={!editableFields?.checkInRequirements}
                  />
                </CustomFormRow>
              </GridCol>
              <GridCol size={6}>
                <IdentityProofInput readOnly={!editableFields?.checkInRequirements} />
              </GridCol>
            </Grid>
          </div>
        </FormikAccordion>

        <FormikAccordion<PartnerCustomerServiceAndPoliciesFormValues>
          title={formatMessage({ id: "partnerForm.customerServiceInformation.headline" })}
          name="customerServiceInformation"
        >
          <CustomerServiceContactForm
            readOnly={!editableFields?.customerServiceContact}
          />
        </FormikAccordion>

        <FormikAccordion<PartnerCustomerServiceAndPoliciesFormValues>
          title={formatMessage({ id: "partnerForm.termsAndConditions.headline" })}
          name="termsAndConditions"
        >
          <CustomFormRow>
            <Grid>
              <GridCol size={6}>
                <FormikInput
                  name="termsAndConditions.termsAndConditionsUrl"
                  label={formatMessage({
                    id: "partnerForm.termsAndConditions.url.label",
                  })}
                  placeholder="https://www.example.com/terms"
                  type="url"
                  readOnly={!editableFields?.termsAndConditionsUrl}
                  readOnlyValueWhenEmpty={formatMessage({ id: "general.notAvailable" })}
                />
              </GridCol>
            </Grid>
          </CustomFormRow>
        </FormikAccordion>

        {children}
      </Form>
    </Formik>
  );
};

export default PartnerCustomerServiceAndPoliciesForm;
