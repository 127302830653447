import { FormRow } from "@flixbus/honeycomb-react";
import { formatDateForApi, localizeDate } from "@flixbus-phx/marketplace-common";
import { Form, Formik } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import CirculationSelect from "../../shared/components/circulationSelect/CirculationSelect";
import FormikInput from "../../shared/components/formikInput/FormikInput";
import FormikPeriodMultiSelect from "../../shared/components/formikPeriodMultiSelect/FormikPeriodMultiSelect";
import FormikPeriodSelect from "../../shared/components/formikPeriodSelect/FormikPeriodSelect";
import { ScheduleEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import { Partner } from "../../shared/types/schema";
import validationSchema from "./helpers/validationSchema";
import { ScheduleFormFields, ScheduleSubmittableFields } from "./types";

export type ScheduleFormProps = {
  onSubmit: (values: ScheduleSubmittableFields) => void;
  onCancel: () => void;
  partnerId?: Partner["id"];
  defaultValues?: Partial<ScheduleFormFields>;
  editableFields: ScheduleEditableFields;
};

const ScheduleForm: React.FC<ScheduleFormProps> = ({
  onSubmit,
  onCancel,
  partnerId,
  defaultValues,
  children,
  editableFields,
}) => {
  const { formatMessage } = useIntl();

  const initialValues: ScheduleFormFields = {
    name: defaultValues?.name ? defaultValues.name : "",
    period: {
      start: defaultValues?.period?.start,
      end: defaultValues?.period?.end,
    },
    nonOperatingPeriods: defaultValues?.nonOperatingPeriods
      ? defaultValues.nonOperatingPeriods
      : [],
    circulationId: defaultValues?.circulationId ? defaultValues.circulationId : "",
  };

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values) => {
        const formattedValues: ScheduleSubmittableFields = {
          name: values.name !== "" ? values.name : `DRAFT_${localizeDate(new Date())}`,
          period: {
            start: formatDateForApi(values.period.start!),
            end: formatDateForApi(values.period.end!),
          },
          nonOperatingPeriods: values.nonOperatingPeriods?.map(({ start, end }) => ({
            start: formatDateForApi(start),
            end: formatDateForApi(end),
          })),
          circulationId: values.circulationId,
        };
        onSubmit(formattedValues);
      }}
      onReset={onCancel}
    >
      {({ values }) => (
        <Form noValidate>
          <FormRow>
            <FormikInput
              name="name"
              label={formatMessage({ id: "schedule.name" })}
              placeholder="Spring 2024"
              type="text"
              readOnly={!editableFields.name}
            />
          </FormRow>
          <FormRow>
            <FormikPeriodSelect
              name="period"
              label={formatMessage({ id: "schedule.period" })}
              placeholder="Dec 24 2025 - Dec 26 2025"
              required
              readOnly={!editableFields.period}
            />
          </FormRow>
          <FormRow>
            <FormikPeriodMultiSelect
              name="nonOperatingPeriods"
              label={formatMessage({ id: "scheduleDetail.NonOperationalDays.label" })}
              earliestStartDate={values.period.start}
              latestEndDate={values.period.end}
              readOnly={
                !(values.period.start && values.period.end) ||
                !editableFields.nonOperatingPeriods
              }
            />
          </FormRow>
          {partnerId && editableFields.circulationId.isShown && (
            <FormRow data-id="circulationCapacity-wrapper">
              <CirculationSelect
                label={formatMessage({ id: "scheduleDetail.capacity.label" })}
                name="circulationId"
                partnerId={partnerId}
                readOnly={!editableFields.circulationId.isEditable}
                isClearable={!initialValues.circulationId}
              />
            </FormRow>
          )}
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default ScheduleForm;
