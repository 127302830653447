import { Button, Skeleton } from "@flixbus/honeycomb-react";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import MultiSelect from "../../../../shared/components/multiSelect/MultiSelect";
import SelectApiError from "../../../../shared/components/selectApiError/SelectApiError";
import { useFindAllAvailableBrandsQuery } from "../../api/operations.generated";
import createNotificationForBrandUpdate from "../../helpers/createNotificationForBrandUpdate/createNotificationForBrandUpdate";
import filterAllBrandsBySelectedBrands from "../../helpers/filterAllBrandsBySelectedBrands/filterAllBrandsBySelectedBrands";
import findBrandsByIds from "../../helpers/findBrandsByIds/findBrandsByIds";
import { Brand } from "../../types";
import BrandsInputSelectTemplate from "../../ui/brandsInputSelectTemplate/BrandsInputSelectTemplate";
import * as css from "./BrandsInputSelect.scss";

type Props = {
  isInUpdateFlow: boolean;
};

const BrandsInputSelect: React.FC<Props> = ({ isInUpdateFlow }) => {
  const { formatMessage } = useIntl();
  const { data, loading, error } = useFindAllAvailableBrandsQuery({
    // Prevents that the user can reuse a brand on a different partner within the same session
    fetchPolicy: "network-only",
  });

  const [, meta, helper] = useField<Array<Brand>>("brands");

  const [selected, setSelected] = React.useState<Array<Brand["id"]>>([]);

  let deleteSelected: Function;

  if (loading) {
    return (
      <BrandsInputSelectTemplate
        SelectElement={<Skeleton height="lg" />}
        ButtonElement={
          <div className={css.loadingButton}>
            <Skeleton height="lg" />
          </div>
        }
      />
    );
  }

  if (data?.findAllAvailableBrands) {
    return (
      <BrandsInputSelectTemplate
        SelectElement={
          <MultiSelect
            placeholder={formatMessage({
              id: "partnerForm.brandsAndPolicies.addBrand.placeholder",
            })}
            options={filterAllBrandsBySelectedBrands(
              data.findAllAvailableBrands,
              meta.value
            )}
            onSelect={(selectedBrands) =>
              setSelected(selectedBrands.map((brand) => brand.value))
            }
            deleteSelection={(func: Function) => {
              deleteSelected = func;
            }}
            maxToShow={1}
          />
        }
        ButtonElement={
          <Button
            appearance="primary"
            onClick={() => {
              helper.setValue([
                ...meta.value,
                ...findBrandsByIds(selected, data.findAllAvailableBrands),
              ]);
              setSelected([]);
              deleteSelected([]);
              createNotificationForBrandUpdate(isInUpdateFlow, false);
            }}
            disabled={!selected.length}
          >
            <FormattedMessage id="partnerForm.brandsAndPolicies.addBrand.buttonText" />
          </Button>
        }
      />
    );
  }

  if (error && !loading) {
    return (
      <BrandsInputSelectTemplate
        SelectElement={
          <SelectApiError info={formatMessage({ id: "error.errorOccured" })} />
        }
        ButtonElement={
          <Button appearance="primary" disabled>
            <FormattedMessage id="partnerForm.brandsAndPolicies.addBrand.buttonText" />
          </Button>
        }
      />
    );
  }

  return <></>;
};

export default BrandsInputSelect;
