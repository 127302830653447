import { useField, useFormikContext } from "formik";
import * as React from "react";
import UnitChangePopup, {
  UnitChangePopupUnits,
} from "../../../../shared/components/unitChangePopup/UnitChangePopup";
import arePoliciesWithUnitsActive from "../../helpers/arePoliciesWithUnitsActive/arePoliciesWithUnitsActive";
import clearUnitFields from "../../helpers/clearUnitFields/clearUnitFields";
import { PoliciesFormValues, UnitTypes } from "../../types";

type Props = {
  name: string;
  unitType: UnitTypes;
  children?: React.ReactNode;
};

// eslint-disable-next-line react/function-component-definition
function UnitChangePopupWrapper<T extends UnitChangePopupUnits>({
  name,
  unitType,
  children,
}: Props): React.ReactElement | null {
  const { values, setValues } = useFormikContext<PoliciesFormValues>();

  const [, meta, { setValue, setTouched }] = useField<T>(name);

  const prevUnit = React.useRef<T>(meta.value);

  const [showPopup, setShowPopup] = React.useState(false);

  React.useEffect(() => {
    if (meta.touched) {
      if (arePoliciesWithUnitsActive(values.policies)) {
        setShowPopup(true);
      } else {
        prevUnit.current = meta.value;
      }
    }
  }, [meta.value]);

  return (
    <>
      {children}
      {showPopup && (
        <UnitChangePopup
          currentUnit={prevUnit.current}
          newUnit={meta.value}
          onConfirm={(resetValues) => {
            if (resetValues) {
              setValues({
                ...values,
                policies: values.policies.map((val) => ({
                  type: val.type,
                  policy: clearUnitFields(unitType, val.policy),
                  additionalInformation: val.additionalInformation,
                })),
              });
            }
            setShowPopup(false);
            prevUnit.current = meta.value;
          }}
          onCancel={() => {
            if (prevUnit.current) {
              setTouched(false);
              setValue(prevUnit.current);
            }
            setShowPopup(false);
          }}
        />
      )}
    </>
  );
}

export default UnitChangePopupWrapper;
