import { Icon, IconMagnifier } from "@flixbus/honeycomb-icons-react";
import { Box, Heading, Input } from "@flixbus/honeycomb-react";
import { Form, Formik } from "formik";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LineEditableFields } from "../../shared/helpers/getEditableFormFields/types";
import * as css from "./LineForbiddenRelationsForm.scss";
import filterForbiddenRelations from "./helpers/filterForbiddenRelations/filterForbiddenRelations";
import { ForbiddenRelationsFormValues } from "./types";
import AddForbiddenRelationsButton from "./ui/addForbiddenRelationsButton/AddForbiddenRelationsButton";
import ForbiddenRelationsList from "./ui/forbiddenRelationsList/ForbiddenRelationsList";

export type LineForbiddenRelationsFormProps = {
  onSubmit: (values: Array<ForbiddenRelationsFormValues>) => void;
  onCancel: () => void;
  editableFields: LineEditableFields["forbiddenRelations"];
  defaultValues?: Array<ForbiddenRelationsFormValues>;
};

const LineForbiddenRelationsForm: React.FC<LineForbiddenRelationsFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  editableFields,
  children,
}) => {
  const { formatMessage } = useIntl();

  const initialValues: {
    forbiddenRelations: Array<ForbiddenRelationsFormValues>;
  } = {
    forbiddenRelations: defaultValues || [],
  };

  const [filterValue, setFilterValue] = React.useState<string>();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        onSubmit(values.forbiddenRelations);
      }}
      onReset={onCancel}
    >
      {({ values }) => (
        <Form noValidate>
          <div className={css.headerWrapper}>
            <Heading size={4}>
              <div>
                <FormattedMessage id="general.forbiddenRelations" /> (
                {values.forbiddenRelations.length})
              </div>
            </Heading>

            <div className={css.searchAddWrapper}>
              <Input
                id="forbidden-relation-search"
                aria-label="forbidden-relation-search"
                iconLeft={<Icon InlineIcon={IconMagnifier} />}
                placeholder={formatMessage({ id: "general.search" })}
                type="search"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setFilterValue(event.target.value)
                }
              />

              {editableFields.forbiddenRelationsAdd && (
                <AddForbiddenRelationsButton isUpdate={!!defaultValues} />
              )}
            </div>
          </div>

          {values.forbiddenRelations.length ? (
            <ForbiddenRelationsList
              forbiddenRelations={filterForbiddenRelations(
                values.forbiddenRelations,
                filterValue
              )}
              isDeleteAllowed={editableFields.forbiddenRelationsDelete}
              isUpdate={!!defaultValues}
            />
          ) : (
            <Box extraClasses={css.box}>
              <FormattedMessage id="lineForm.forbiddenRelations.info.noEntries" />
            </Box>
          )}
          {children}
        </Form>
      )}
    </Formik>
  );
};

export default LineForbiddenRelationsForm;
