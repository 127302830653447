import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikSelect from "../../../../shared/components/formikSelect/FormikSelect";
import InputSkeleton from "../../../../shared/components/inputSkeleton/InputSkeleton";
import SelectApiError from "../../../../shared/components/selectApiError/SelectApiError";
import { Brand, Partner } from "../../../../shared/types/schema";
import { useFindAllBrandsForPartnerQuery } from "../../api/operations.generated";

type Props = {
  partnerId: Partner["id"];
  readOnly?: boolean;
};

const BrandSelect: React.FC<Props> = ({ partnerId, readOnly }) => {
  const { formatMessage } = useIntl();
  const [, meta, helper] = useField<Brand["id"]>("brandId");

  const { loading, data, error } = useFindAllBrandsForPartnerQuery({
    variables: {
      id: partnerId!!,
    },
    onCompleted: (res) => {
      if (res.findPartner?.brands.length && !meta.value) {
        helper.setValue(res.findPartner?.brands[0].value);
      }
    },
  });

  if (loading) {
    return <InputSkeleton />;
  }

  if (error) {
    return (
      <SelectApiError
        label={formatMessage({ id: "lineForm.brand.select.label" })}
        info={formatMessage({ id: "error.errorOccured" })}
      />
    );
  }

  if (data?.findPartner) {
    return (
      <FormikSelect
        name="brandId"
        label={formatMessage({ id: "lineForm.brand.select.label" })}
        placeholder={formatMessage({ id: "lineForm.brand.select.label" })}
        options={data.findPartner.brands}
        readOnly={readOnly}
        required
      />
    );
  }

  return <></>;
};

export default BrandSelect;
