import { Icon, IconComponent } from "@flixbus/honeycomb-icons-react";
import { Button, ButtonGroup, ButtonProps } from "@flixbus/honeycomb-react";
import { useFormikContext } from "formik";
import * as React from "react";
import * as css from "./FormikButtons.scss";

export type FormikButtonsProps = {
  submitButtonText: string;
  submitButtonLoading?: boolean;
  submitButtonAppearance: ButtonProps<"button">["appearance"];
  submitButtonIcon?: IconComponent;
  disableSubmitWhenNotDirty?: boolean;
  resetButtonText?: string;
  resetButtonAppearance?: ButtonProps<"button">["appearance"];
  resetButtonIcon?: IconComponent;
};

const FormikButtons: React.FC<FormikButtonsProps> = ({
  submitButtonText,
  submitButtonLoading = false,
  submitButtonAppearance,
  submitButtonIcon,
  disableSubmitWhenNotDirty = false,
  resetButtonText,
  resetButtonAppearance,
  resetButtonIcon,
  children,
}) => {
  const { dirty } = useFormikContext();

  return (
    <div className={css.buttonWrapper}>
      <div className={css.flex}>
        {children && <div>{children}</div>}
        <div>
          <ButtonGroup>
            {resetButtonText && (
              <Button type="reset" appearance={resetButtonAppearance}>
                {resetButtonIcon && <Icon InlineIcon={resetButtonIcon} />}
                {resetButtonText}
              </Button>
            )}
            <Button
              type="submit"
              appearance={submitButtonAppearance}
              loading={submitButtonLoading}
              disabled={submitButtonLoading || (disableSubmitWhenNotDirty && !dirty)}
            >
              {submitButtonText}
              {submitButtonIcon && <Icon InlineIcon={submitButtonIcon} />}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
};

export default FormikButtons;
