import { Feature, hasUserPermission } from "@flixbus-phx/marketplace-common";
import { ScheduleEditableFields } from "../types";

const getEditableScheduleFields = (
  isCreation: boolean,
  isReadOnly: boolean
): ScheduleEditableFields => {
  return {
    name: !isReadOnly ? hasUserPermission(Feature.EDIT_SCHEDULE_NAME_UNPUBLISHED) : false,
    period: !isReadOnly
      ? hasUserPermission(Feature.EDIT_SCHEDULE_PERIOD_UNPUBLISHED)
      : false,
    nonOperatingPeriods: !isReadOnly
      ? hasUserPermission(Feature.EDIT_SCHEDULE_NON_OPERATIONAL_PERIOD_UNPUBLISHED)
      : false,
    circulationId: {
      isShown: isCreation
        ? hasUserPermission(Feature.EDIT_SCHEDULE_INITIAL_CAPACITY_UNPUBLISHED)
        : true,
      isEditable: !isReadOnly
        ? hasUserPermission(Feature.EDIT_SCHEDULE_INITIAL_CAPACITY_UNPUBLISHED)
        : false,
    },
  };
};

export default getEditableScheduleFields;
