import { ChoiceWrapper, ChoiceWrapperItem } from "@flixbus/honeycomb-react";
import { SelectOption } from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import * as css from "./FormikChoiceWrapper.scss";

export type Props = {
  name: string;
  label: string;
  options: Array<SelectOption>;
  readOnly?: boolean;
  isMulti?: boolean;
  required?: boolean;
};

const FormikChoiceWrapper: React.FC<Props> = ({
  name,
  label,
  options,
  readOnly = false,
  isMulti = false,
  required = false,
}) => {
  const [, meta, helper] = useField(name);

  const updateSelectedValues = (event: React.MouseEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const { value } = target;

    if (target.checked) {
      return [...meta.value, value];
    }

    return meta.value.filter((val: string) => val !== value);
  };

  return (
    <>
      <ChoiceWrapper
        legend={`${label}${required && !readOnly ? " *" : ""}`}
        extraClasses={css.choiceWrapper}
        multi={isMulti}
        error={!!(meta.error && meta.touched)}
        infoError={meta.error}
      >
        {options.map((option, i) => (
          <ChoiceWrapperItem
            key={option.value}
            id={`${name}-option-${i}`}
            name={`${name}-choiceWrapper`}
            label={option.label}
            value={option.value}
            checked={meta.value.includes(option.value)}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              helper.setValue(updateSelectedValues(event));
              // https://github.com/formium/formik/issues/2083#issuecomment-571259235
              helper.setTouched(true, false);
            }}
            disabled={readOnly}
          />
        ))}
      </ChoiceWrapper>
    </>
  );
};

export default FormikChoiceWrapper;
