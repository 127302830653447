import { IconBusTrainSolid, IconPublicSolid } from "@flixbus/honeycomb-icons-react";
import { Formik } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import BusinessRegionChecklistFilter from "../../formFields/businessRegionChecklistFilter/BusinessRegionChecklistFilter";
import MeansOfTransportFilter from "../../formFields/meansOfTransportFilter/MeansOfTransportFilter";
import {
  createInitialValues,
  createSubmittableValues,
} from "../../helper/lineFilterValues/lineFilterValues";
import { LineFilterFormSubmittableValues, SearchType } from "../../types";
import FilterForm from "../filterForm/FilterForm";

export const menu = [
  {
    label: <FormattedMessage id="general.bizRegion" />,
    icon: IconPublicSolid,
    content: <BusinessRegionChecklistFilter />,
  },
  {
    label: <FormattedMessage id="lineForm.meansOfTransport.label" />,
    icon: IconBusTrainSolid,
    content: <MeansOfTransportFilter type={SearchType.LINES} />,
  },
];

export type LineFilterFormProps = {
  defaultValues?: LineFilterFormSubmittableValues;
  onReset: () => void;
  onSubmit: (values: LineFilterFormSubmittableValues) => void;
};

const LineFilterForm: React.FC<LineFilterFormProps> = ({
  defaultValues,
  onReset,
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={createInitialValues(defaultValues)}
      onSubmit={(values) => {
        onSubmit(createSubmittableValues(values));
      }}
      onReset={onReset}
      validateOnBlur={false}
      validate={async () => {
        return null;
      }}
    >
      <FilterForm menuItems={menu} />
    </Formik>
  );
};

export default LineFilterForm;
