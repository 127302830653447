import { Icon, IconArrowDown } from "@flixbus/honeycomb-icons-react";
import { Input as HCInput } from "@flixbus/honeycomb-react";
import * as React from "react";
import { useIntl } from "react-intl";

export type CalendarInputProps = {
  label: string;
  required: boolean;
  preventAutoFocus?: boolean;
  placeholder?: string;
  errorMessage?: string;
  value: string;
  onFocus: () => void;
};

const CalendarInput: React.FC<CalendarInputProps> = ({
  label,
  required,
  preventAutoFocus = false,
  placeholder,
  errorMessage,
  value,
  onFocus,
}) => {
  const { formatMessage } = useIntl();

  return (
    <HCInput
      label={label + (required ? " *" : "")}
      id="period-input"
      value={value}
      readOnly
      onFocus={onFocus}
      tabIndex={preventAutoFocus ? -1 : undefined}
      placeholder={placeholder || formatMessage({ id: "general.select" })}
      iconRight={<Icon InlineIcon={IconArrowDown} />}
      valid={errorMessage ? false : undefined}
      infoError={errorMessage || undefined}
    />
  );
};

export default CalendarInput;
