import {
  MultiIconLabel,
  ReadOnlyFormField,
  SelectOption,
} from "@flixbus-phx/marketplace-common";
import { useField } from "formik";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import Select from "../select/Select";
import * as css from "./FormikSelect.scss";

export type FormikSelectProps = {
  label?: string;
  placeholder?: string;
  name: string;
  required?: boolean;
  options: Array<SelectOption>;
  readOnly?: boolean;
  isClearable?: boolean;
  infoText?: string;
};

const FormikSelect: React.FC<FormikSelectProps> = ({
  label,
  placeholder,
  name,
  required,
  options,
  readOnly,
  isClearable,
  infoText,
}) => {
  const [, meta, helper] = useField(name);

  const initialValue = options.find((option) => option.value === meta.value);

  const getReadOnlyText = (value: SelectOption) => {
    if (value.multiIconLabel) {
      return (
        <MultiIconLabel
          label={value.label}
          multiIconLabel={value.multiIconLabel}
          extraClasses={css.multiIconWrapper}
        />
      );
    }

    return value.label;
  };

  if (readOnly) {
    return (
      <ReadOnlyFormField label={label}>
        {initialValue ? (
          getReadOnlyText(initialValue)
        ) : (
          <FormattedMessage id="general.notAvailable" />
        )}
      </ReadOnlyFormField>
    );
  }

  return (
    <Select
      label={label && `${label}${required ? " *" : ""}`}
      initialValue={initialValue}
      placeholder={placeholder}
      options={options}
      onSelect={(selectedValue) => {
        helper.setTouched(true);
        helper.setValue(selectedValue?.value);
      }}
      onClear={isClearable ? () => helper.setValue("") : undefined}
      onBlur={() => helper.setTouched(true)}
      errorText={meta.error && meta.touched ? meta.error : undefined}
      infoText={infoText}
    />
  );
};

export default FormikSelect;
