import { Grid, GridCol, Heading } from "@flixbus/honeycomb-react";
import { hasUserAtLeastOnePermission, Feature } from "@flixbus-phx/marketplace-common";
import * as React from "react";
import ScheduleStatusTagTooltip from "../../shared/components/scheduleStatusTagTooltip/ScheduleStatusTagTooltip";
import { ScheduleDetailQuery } from "../scheduleDetail/api/operations.generated";
import * as css from "./ScheduleHeader.scss";
import ScheduleActions from "./containers/scheduleActions/ScheduleActions";
import ScheduleHeaderWrapper from "./ui/scheduleHeaderWrapper/ScheduleHeaderWrapper";

type Props = {
  schedule: ScheduleDetailQuery["findSchedule"];
};

const ScheduleHeader: React.FC<Props> = ({ schedule }) => {
  return (
    <ScheduleHeaderWrapper scheduleId={schedule.id}>
      <Grid>
        <GridCol size={11} extraClasses={css.wrapper} data-id="schedule-name-wrapper">
          <ScheduleStatusTagTooltip
            scheduleId={schedule.id}
            displayedScheduleStatus={schedule.displayedScheduleStatus}
            isScheduleOutdated={schedule.isOutdated}
          />

          <Heading size={1} extraClasses={css.scheduleName}>
            {schedule.name}
          </Heading>
        </GridCol>
        {hasUserAtLeastOnePermission([
          Feature.CREATE_SCHEDULE_CONCEPT_CHANGE,
          Feature.CREATE_SCHEDULE_COPY,
          Feature.DELETE_SCHEDULE,
          Feature.VIEW_SCHEDULE_STATUS_HISTORY,
          Feature.EDIT_SCHEDULE_STATUS,
        ]) ? (
          <GridCol size={1}>
            <ScheduleActions
              scheduleId={schedule.id}
              scheduleName={schedule.name}
              scheduleStatus={schedule.status}
              displayedScheduleStatus={schedule.displayedScheduleStatus}
              originalScheduleStatus={schedule.originalSchedule.status}
              lineId={schedule.line.id}
              readOnly={schedule.readOnly}
            />
          </GridCol>
        ) : (
          <></>
        )}
      </Grid>
    </ScheduleHeaderWrapper>
  );
};

export default ScheduleHeader;
