import { Icon, IconArrowRight } from "@flixbus/honeycomb-icons-react";
import { Button } from "@flixbus/honeycomb-react";
import {
  addNotification,
  formatErrorMessage,
  NotificationType,
} from "@flixbus-phx/marketplace-common";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  ScheduleDetailQuery,
  useUpdateScheduleMutation,
} from "../../api/operations.generated";
import OnSalePeriodsText from "../../ui/onSalePeriodsText/OnSalePeriodsText";
import ScheduleDetailPanel from "../../ui/scheduleDetailPanel/ScheduleDetailPanel";
import * as css from "./ScheduleInfo.scss";

type Props = {
  schedule: ScheduleDetailQuery["findSchedule"];
};

const ScheduleInfo: React.FC<Props> = ({ schedule }) => {
  const { formatMessage } = useIntl();
  const [panelActive, setPanelActive] = React.useState(false);

  const [updateSchedule, { loading: updateScheduleLoading }] = useUpdateScheduleMutation({
    onError: (error) =>
      addNotification({
        message: `Schedule update failed: ${formatErrorMessage(error.message)}`,
        type: NotificationType.Danger,
      }),
    onCompleted: () => {
      addNotification({
        message: formatMessage({ id: "scheduleDetail.submit.notification.success" }),
      });
      setPanelActive(false);
    },
  });

  return (
    <div className={css.wrapper}>
      <OnSalePeriodsText onSalePeriods={schedule.onSalePeriods} />
      <Button
        extraClasses={css.detailsButton}
        size="sm"
        onClick={() => setPanelActive(true)}
        data-id="schedule-detail-button"
      >
        <FormattedMessage id="scheduleDetail.header" />
        <Icon InlineIcon={IconArrowRight} />
      </Button>
      <ScheduleDetailPanel
        schedule={schedule}
        active={panelActive}
        submitLoading={updateScheduleLoading}
        onSubmit={(values) =>
          updateSchedule({ variables: { id: schedule.id, ...values } })
        }
        onCancel={() => setPanelActive(false)}
      />
    </div>
  );
};

export default ScheduleInfo;
