import { Icon, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Tag } from "@flixbus/honeycomb-react";
import {
  getPeriodAsString,
  PeriodPicker,
  PeriodPickerProps,
} from "@flixbus-phx/marketplace-common";
import { isWithinInterval } from "date-fns";
import { useField } from "formik";
import * as React from "react";

import { FormattedMessage } from "react-intl";
import * as css from "./FormikPeriodMultiSelect.scss";

type Period = {
  start: Date;
  end: Date;
};

export type FormikPeriodMultiSelectProps = {
  name: string;
  label: string;
  earliestStartDate?: Date;
  latestEndDate?: Date;
  readOnly?: boolean;
};

const FormikPeriodMultiSelect: React.FC<FormikPeriodMultiSelectProps> = ({
  name,
  label,
  earliestStartDate,
  latestEndDate,
  readOnly,
}) => {
  const [, { value }, helper] = useField<Period[]>(name);

  const labelElem = <label className={css.label}>{label}</label>;

  // Remove all periods that are outside of the earliestStartDate and latestEndDate
  React.useEffect(() => {
    if (earliestStartDate && latestEndDate && value.length) {
      const validPeriods = value
        .map((val) =>
          isWithinInterval(val.start, { start: earliestStartDate, end: latestEndDate }) &&
          isWithinInterval(val.end, { start: earliestStartDate, end: latestEndDate })
            ? val
            : ""
        )
        .filter((val) => val !== "");

      if (validPeriods.length !== value.length) {
        helper.setValue(validPeriods, false);
      }
    }
  }, [earliestStartDate, latestEndDate]);

  if (readOnly) {
    return (
      <div className={css.wrapper}>
        {labelElem}
        {value.length ? (
          value.map((val) => {
            return (
              <Tag
                display="subtle"
                extraClasses={`${css.tag} ${css.tagSpacer}`}
                disabled
                key={val.start.toString()}
              >
                {getPeriodAsString(val.start, val.end)}
              </Tag>
            );
          })
        ) : (
          <Tag display="subtle" disabled>
            <Icon InlineIcon={IconPlus} />
            <FormattedMessage id="scheduleDetail.NonOperationalDays.tag.label" />
          </Tag>
        )}
      </div>
    );
  }

  const deleteItem = (index: number) => {
    helper.setTouched(true);
    const newValue = [...value];
    newValue.splice(index, 1);
    helper.setValue(newValue);
  };

  const addItem = (item: Period) => {
    helper.setTouched(true);
    helper.setValue([...value, item]);
  };

  const changeItem = (item: Period, index: number) => {
    helper.setTouched(true);
    const newValue = [...value];
    newValue[index] = item;
    helper.setValue(newValue);
  };

  const commonPeriodPickerProps = {
    inputElement: "tag" as PeriodPickerProps["inputElement"],
    earliestStartDate: earliestStartDate || new Date(),
    latestEndDate: latestEndDate || new Date(),
    label,
  };

  return (
    <div className={css.wrapper}>
      {labelElem}
      {value.map((val, index) => {
        return (
          <div className={css.tagSpacer} key={val.start.toString()}>
            <PeriodPicker
              {...commonPeriodPickerProps}
              periodStart={val.start}
              periodEnd={val.end}
              onChange={(selectedStart, selectedEnd) => {
                changeItem({ start: selectedStart, end: selectedEnd }, index);
              }}
              onFocus={() => {
                helper.setTouched(true);
              }}
              onDelete={() => {
                deleteItem(index);
              }}
            />
          </div>
        );
      })}
      <div className={css.tagSpacer}>
        <PeriodPicker
          {...commonPeriodPickerProps}
          onChange={(selectedStart, selectedEnd) => {
            addItem({ start: selectedStart, end: selectedEnd });
          }}
          onFocus={() => {
            helper.setTouched(true);
          }}
        />
      </div>
    </div>
  );
};

export default FormikPeriodMultiSelect;
