import { IconTime } from "@flixbus/honeycomb-icons-react";
import { Box, FormRow } from "@flixbus/honeycomb-react";
import cx from "classnames";
import { useField } from "formik";
import * as React from "react";
import { useIntl } from "react-intl";
import FormikCheckbox from "../../../../shared/components/formikCheckbox/FormikCheckbox";
import FormikInput from "../../../../shared/components/formikInput/FormikInput";
import FormikSelectGroup from "../../../../shared/components/formikSelectGroup/FormikSelectGroup";
import { StationTransferInformationOpeningHours } from "../../../../shared/types/schema";
import { TransferTime } from "../../types";
import TransferOpeningHoursDaySelect from "../transferOpeningHoursDaySelect/TransferOpeningHoursDaySelect";
import TransferOpeningHoursFormRow from "../transferOpeningHoursFormRow/TransferOpeningHoursFormRow";
import * as css from "./TransferOpeningHoursInput.scss";

type Props = {
  readOnly?: boolean;
};

const TransferOpeningHoursInput: React.FC<Props> = ({ readOnly }) => {
  const { formatMessage } = useIntl();

  const [, metaIsTransferStation] = useField<boolean>(
    "transferInformation.isTransferStation"
  );
  const [, metaTransferTime] = useField<TransferTime>("transferInformation.transferTime");
  const [, metaOpeningHours] = useField<
    Array<Array<StationTransferInformationOpeningHours>>
  >("transferInformation.transferOpeningHours");

  const isTransferStation = metaIsTransferStation.value;

  return (
    <Box extraClasses={css.box}>
      <FormRow
        extraClasses={cx(css.checkBox, isTransferStation ? css.checked : css.unchecked)}
      >
        <FormikCheckbox
          label={formatMessage({ id: "station.transferStation" })}
          name="transferInformation.isTransferStation"
          readOnly={readOnly}
        />
      </FormRow>
      {isTransferStation && (
        <>
          <FormRow spacing="3">
            <div className={css.transferTime}>
              <div className={css.transferTimeSelect}>
                <FormikSelectGroup
                  name="transferInformation.transferTime"
                  label={formatMessage({ id: "station.transferTime" })}
                  defaultOption={TransferTime.DYNAMIC}
                  defaultOptionLabel={formatMessage({
                    id: "station.transferTime.dynamic",
                  })}
                  alternativeOption={TransferTime.FIXED}
                  alternativeOptionLabel={formatMessage({
                    id: "station.transferTime.fixed",
                  })}
                  required
                  readOnly={readOnly}
                  tooltipContent={
                    <ul className={css.tooltipContent}>
                      <li>
                        {formatMessage({
                          id: "station.transferTime.info.dynamic",
                        })}
                      </li>
                      <li>
                        {formatMessage({
                          id: "station.transferTime.info.fixed",
                        })}
                      </li>
                    </ul>
                  }
                />
              </div>

              {metaTransferTime.value === TransferTime.FIXED && (
                <FormikInput
                  label={`${formatMessage({
                    id: "station.minTransferTime",
                  })} (${formatMessage({ id: "unit.minutes" })})`}
                  name="transferInformation.minimumTransferMinutes"
                  type="number"
                  icon={IconTime}
                  required
                  readOnly={readOnly}
                />
              )}
            </div>
          </FormRow>
          <FormRow>
            {!readOnly && <TransferOpeningHoursDaySelect />}
            {metaOpeningHours.value.map((openingHoursPerDay, indexOfDay) => {
              return openingHoursPerDay.map((openingHour, indexWithinDay, arr) => {
                return (
                  <TransferOpeningHoursFormRow
                    // Rerendering when the index changes is exactly what we want here, thats why we
                    // eslint-disable-next-line react/no-array-index-key
                    key={indexWithinDay}
                    indexOfDay={indexOfDay}
                    indexWithinDay={indexWithinDay}
                    isLastWithinDay={indexWithinDay === arr.length - 1}
                    readOnly={readOnly}
                  />
                );
              });
            })}
          </FormRow>
        </>
      )}
    </Box>
  );
};

export default TransferOpeningHoursInput;
