import { formatMessage } from "@flixbus-phx/marketplace-common";
import * as Yup from "yup";

export default () =>
  Yup.object().shape({
    name: Yup.string(),
    period: Yup.object().shape({
      start: Yup.date().required(formatMessage("lineDetail.schedules.noPeriod")),
      end: Yup.date().required(formatMessage("lineDetail.schedules.noPeriod")),
    }),
    nonOperatingPeriodInput: Yup.array().of(
      Yup.object().shape({
        start: Yup.date(),
        end: Yup.date(),
      })
    ),
    circulationId: Yup.string(),
  });
