import { Icon, IconClose, IconPlus } from "@flixbus/honeycomb-icons-react";
import { Tag } from "@flixbus/honeycomb-react";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import * as css from "./CalendarTag.scss";

export type CalendarTagProps = {
  value: string;
  onClick: () => void;
  onDelete: () => void;
};

const CalendarTag: React.FC<CalendarTagProps> = ({ value, onClick, onDelete }) => {
  if (value) {
    return (
      <Tag display="subtle" extraClasses={css.tag}>
        <span onClick={onClick}>{value}</span>
        <Icon InlineIcon={IconClose} onClick={onDelete} />
      </Tag>
    );
  }

  return (
    <Tag display="subtle" onClick={onClick} extraClasses={css.tag}>
      <Icon InlineIcon={IconPlus} />
      <FormattedMessage id="scheduleDetail.NonOperationalDays.tag.label" />
    </Tag>
  );
};

export default CalendarTag;
